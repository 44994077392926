import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import Growth from "../../assets/svg/growth.svg";
import Health from "../../assets/svg/health.svg";
import Momentum from "../../assets/svg/momentum.svg";
import MonkScore from "../../assets/svg/monkfactor.svg";
import Profitability from "../../assets/svg/profitability.svg";
import Shareholder from "../../assets/svg/shareholderIcon.svg";
import Transparency from "../../assets/svg/transparency.svg";
import Valuation from "../../assets/svg/valuation.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import { displayNA, handleTitleSubtext } from "../../utils/formatChartNumber";

interface CardAccordianProps {
  cardName: string;
  children?: ReactNode;
  statusRating?: number;
}

const CardAccordian = ({
  cardName,
  children,
  statusRating,
}: CardAccordianProps) => {

  const [index, setIndex] = useState(1);

  return (
    <Box
      bgColor="white"
      borderRadius="20px"
      className="parent_card_height"
      width="100%"
    >
      <Accordion
        allowMultiple
        backgroundColor="#F9FAFB"
        borderRadius="20px"
        p={4}
        index={[index]}
      >
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <h2 onClick={() => setIndex(index === 0 ? -1 : 0)}>
                <AccordionButton
                  display="flex"
                  justifyContent="space-between"
                  _hover={{ bgColor: "none" }}
                  p={0}
                >
                  <Flex>
                    <img
                      src={
                        cardName === "Valuation"
                          ? Valuation
                          : cardName === "Growth"
                            ? Growth
                            : cardName === "Profitability"
                              ? Profitability
                              : cardName === "Health"
                                ? Health
                                : cardName === "Investor Friendliness"
                                  ? Shareholder
                                  : cardName === "Earnings Quality"
                                    ? Transparency
                                    : cardName === "Momentum"
                                      ? Momentum
                                      : cardName === "MonkScore"
                                        ? MonkScore
                                        : ""
                      }
                      alt=""
                      style={{
                        borderRadius: "10%",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                    <Box
                      pl="12px"
                      display="flex"
                      flexDir="column"
                      justifyContent="center"
                    >
                      <Text
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="600"
                        lineHeight="26px"
                        display="flex"
                        justifyContent="start"
                      >
                        {cardName}
                      </Text>
                      <Text
                        fontSize="13px"
                        fontWeight="400"
                        lineHeight="20px"
                        color="#6C727F"
                        textAlign="left"
                      >
                        {cardName === "Valuation" ? (
                          <FormattedMessage
                            id="valuation_heading_text"
                            values={{
                              status: handleTitleSubtext(statusRating || 0),
                            }}
                          />
                        ) : cardName === "Growth" ? (
                          <FormattedMessage
                            id="growth_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "Profitability" ? (
                          <FormattedMessage
                            id="profitability_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "Health" ? (
                          <FormattedMessage
                            id="health_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "Investor Friendliness" ? (
                          <FormattedMessage
                            id="shareholder_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "Earnings Quality" ? (
                          <FormattedMessage
                            id="transparency_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "Momentum" ? (
                          <FormattedMessage
                            id="momentum_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : cardName === "MonkScore" ? (
                          <FormattedMessage
                            id="health_heading_text"
                            values={{
                              status: handleTitleSubtext(
                                statusRating ? statusRating : 0,
                              ),
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Flex>
                      <Text
                        fontWeight={400}
                        fontSize="12px"
                        lineHeight="20px"
                        me={2}
                        color="#6C727F"
                      >
                        {displayNA(statusRating ? statusRating : 0)}
                      </Text>
                      <Box
                        height={21}
                        width={21}
                        backgroundColor={
                          statusRating && statusRating >= 90
                            ? "green"
                            : statusRating &&
                              statusRating >= 70 &&
                              statusRating < 90
                              ? "#58CC02"
                              : statusRating &&
                                statusRating >= 50 &&
                                statusRating < 70
                                ? "#9BE067"
                                : statusRating &&
                                  statusRating >= 20 &&
                                  statusRating < 50
                                  ? "#FFC800"
                                  : statusRating && statusRating >= 1 && statusRating < 20
                                    ? "#FF4B4B"
                                    : "#C9CACB "
                        }
                        me={3}
                        borderRadius={50}
                      ></Box>
                    </Flex>
                    {isExpanded ? (
                      <IoIosArrowUp fontSize="18px" color="#999A9B" />
                    ) : (
                      <IoIosArrowDown fontSize="18px" color="#999A9B" />
                    )}
                  </Flex>
                </AccordionButton>
              </h2>
              {isExpanded && (
                <Box mt={5} px={2} width="100%">
                  {children}
                </Box>
              )}
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default CardAccordian;
