import { Flex, Text, useToast } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { ActionableCompanySearch } from "../../layout/TopBar/CommonCompanySearch";
import { useAddCompaniesMutation } from "../../services/watchlistPortfolioApi";
import { openModal } from "../../store/slices/modal";
import {
  fetchOne,
  watchlist as storeWatchlist,
} from "../../store/slices/watchlist";
import { ICompany } from "../../types/Stock";
import { CommonModal } from "./Common";

interface Props {
  isOpen: boolean;
  is: "watchlist" | "portfolio";
  onSaveSuccess: () => void;
  onOpen?: () => void;
  onClose: () => void;
}

export function AddCompanyModal({ isOpen, onClose, is, onSaveSuccess }: Props) {
  const watchlist = useSelector(storeWatchlist);
  const [searchValue, setSearchValue] = useState("");
  const clearSearch = () => setSearchValue("");
  const { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const [addCompany] = useAddCompaniesMutation();

  const addedCompanyPromise = async (stock: ICompany) => {
    try {
      const res = await addCompany({
        id: watchlist?.id ?? id ?? "",
        isWatchlist: watchlist?.isWatchlist,
        companyIds: [],
        tickers: [stock.ticker],
      }).unwrap();
      onSaveSuccess();
      dispatch(fetchOne({ is, id: id ?? "" }) as any);
      return Promise.resolve(true);
    } catch (error: any) {
      console.log(error);
      if (error?.status === 401 && !error.data.success) {
        console.log(`Reached`);
        onClose();
        dispatch(
          openModal({
            name: MODAL_TYPES_ENUM.pricing,
            link: `${watchlist?.isWatchlist ? "watchlist" : "portfolio"}-${
              error.data.limit
            }`,
            type: "pricing",
          }),
        );
      }

      return Promise.reject(error);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={() => {
        clearSearch();
        onClose();
      }}
    >
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Flex alignItems={"center"} flexDirection={"column"}>
          <PlusIcon />
          <Text
            fontSize="14px"
            fontWeight={"600"}
            lineHeight={"17px"}
            align="center"
            fontFamily="Poppins"
            noOfLines={2}
            mb={2}
            mt={2}
          >
            {`Add a company to “ ${watchlist?.name ?? ""} ”`}
          </Text>
          <Text
            fontWeight="300"
            fontSize="14px"
            lineHeight="24px"
            fontFamily="Poppins"
            noOfLines={2}
            mb={4}
          >
            Search for a company and click on its name to add it.
          </Text>
        </Flex>
        <ActionableCompanySearch
          searchValue={searchValue}
          onChange={onChange}
          clearSearch={clearSearch}
          onClickStock={async (stock) => {
            toast.promise(addedCompanyPromise(stock) as any, {
              success: {
                title: "Company added",
                description: `Looks great you added ${stock.companyName}`,
              },
              error: (error:any)=> {
                 console.log(error)
                 return { title: ":) Upss!!!", description: error?.data?.message ?? "Something wrong." }
              },
              loading: { title: "Pending", description: "Adding company" },
            });
            clearSearch();
          }}
        />
      </Flex>
    </CommonModal>
  );
}
