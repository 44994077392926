import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaymentState {
  paymentMethod: {
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  } | null;
  paymentHistory: Array<{
    id: string;
    amount: number;
    status: string;
    date: number;
    receipt: string;
  }>;
}

const initialState: PaymentState = {
  paymentMethod: null,
  paymentHistory: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentMethod: (
      state,
      action: PayloadAction<PaymentState["paymentMethod"]>,
    ) => {
      state.paymentMethod = action.payload;
    },
    setPaymentHistory: (
      state,
      action: PayloadAction<PaymentState["paymentHistory"]>,
    ) => {
      state.paymentHistory = action.payload;
    },
    clearPaymentData: (state) => {
      state.paymentMethod = null;
      state.paymentHistory = [];
    },
  },
});

export const { setPaymentMethod, setPaymentHistory, clearPaymentData } =
  paymentSlice.actions;
export default paymentSlice.reducer;
