import {
  Box,
  Button,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SkeletonCard } from "../components/common/skeleton/card";
import ChangePasswordModal from "../components/Modals/ChangePassword";
import PricingCards from "../components/Modals/PricingModal/pricing-cards";
import SubscriptionDetails from "../components/Modals/SubscriptionDetails/SubscriptionDetails";
import { PaymentMethodStripe } from "../components/Payment/PaymentMethodStripe";
import { PaymentHistory } from "../components/Table/PaymentHistoryTable";
import { PLAN_LIMITS } from "../constants/plans";
import { useLocalStorage } from "../hooks/useLocalStorage";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";
import { useFetchSubscriptionDataQuery } from "../services/userApi";
import { setActualUser } from "../store/slices/user";
import { cancelSubscription } from "../utils/subscription";
import { MODAL_TYPES_ENUM } from '../constants/variables';
import { openModal } from '../store/slices/modal';

export default function ProfilePage() {
  const toast = useToast();
  const tabs = ["Account", "Subscription", "Usage"];
  const [isMonthly, setIsMonthly] = useState(true);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [currentView, setCurrentView] = useState("subscriptionDetails");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? "");
  const {
    isLoading,
    data: subscriptionData,
    refetch,
  } = useFetchSubscriptionDataQuery(accessToken);

  console.log("subscriptionData", subscriptionData);

  useEffect(() => {
    if (subscriptionData?.subscription?.plan.includes("YEARLY")) {
      setIsMonthly(false);
    }
  }, [subscriptionData]);

  useEffect(() => {
    refetch();
  }, [accessToken]);

  const dispatch = useDispatch();

  const handleSignOut = () => {
    localStorage.clear();
    setAccessToken("");
    localStorage.removeItem("accessToken");
    dispatch(setActualUser());
    window.location.replace("/");
  };

  useEffect(() => {
    if (
      (!subscriptionData?.subscription ||
        subscriptionData.subscription.plan === "free") &&
      currentView !== "pricingCards"
    ) {
      setCurrentView("pricingCards");
    }
  }, [subscriptionData, currentView]);

  const renderComponent = useCallback(() => {
    switch (currentView) {
      case "pricingCards":
        return (
          <PricingCards
            isMonthly={isMonthly}
            setIsMonthly={setIsMonthly}
            showFree={true}
            // showCurrentSubscription
          />
        );
      case "paymentMethod":
        return isLoading ? (
          <SkeletonCard />
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentMethodStripe card={subscriptionData?.paymentMethod} />
          </Elements>
        );
      case "paymentHistory":
        return isLoading ? (
          <SkeletonCard />
        ) : (
          <PaymentHistory data={subscriptionData?.paymentHistory} />
        );
      default:
        return (
          <SubscriptionDetails
            onChangePlan={() => setCurrentView("pricingCards")}
            onManagePaymentMethod={() => setCurrentView("paymentMethod")}
            onViewPaymentHistory={() => setCurrentView("paymentHistory")}
            onCancelMembership={async () => {
              await cancelSubscription(accessToken, toast, refetch);
            }}
            subscriptionData={subscriptionData}
          />
        );
    }
  }, [currentView, isMonthly, subscriptionData]);

  const RenderMetrics = ({
    metric,
    title,
    isMonthly,
  }: {
    title: string;
    metric: string;
    isMonthly: boolean;
  }) => {
    const metricMap = {
      numAnalysis: "analysis",
      numPortfolio: "portfolio",
      numWatchlist: "watchlist",
    };

    const metricFormatted = metricMap?.[metric];

    const plan = subscriptionData?.subscription?.plan;
    const limits = PLAN_LIMITS[plan];

    const numData = subscriptionData?.usage
      ? subscriptionData.usage?.[metricFormatted].used
      : 0;

    const totalData = subscriptionData?.usage
      ? subscriptionData?.usage?.[metricFormatted].limit
      : limits;

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
        justifyItems={"start"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Text>{title}</Text>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            gap={"10px"}
          >
            {numData}/ {totalData}
          </Box>
        </Box>

        <Progress
          // value={totalData !== 0 ? (numData * 100) / totalData : 0}
          value={(numData * 100) / totalData}
          max={100}
          size="lg"
          colorScheme="blue"
          width="100%"
          borderRadius={"md"}
        />
      </Box>
    );
  };

  const handleChangePassword = () => {
    dispatch(
      openModal({
        type: MODAL_TYPES_ENUM.changePassword,
        name: "Change Password",
        link:""
      }),
    );
  };

  return (
    <MobileContent>
      <DesktopContent>
        <Box display={"flex"} flexDirection={"column"} gap={"20px"} padding={{base: "20px", md: "0px"}}>
          <Text
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="600"
            fontSize="28px"
            lineHeight="36px"
            textAlign="left"
            color="#021425"
          >
            Settings
          </Text>
          <Text>
            View about your account, subscription and usage of the platform
          </Text>
          <Tabs
            onChange={() => {
              setCurrentView("subscriptionDetails");
            }}
          >
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab} value={tab}>
                  {tab}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel key={"account"} tabIndex={0}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  gap={"20px"}
                  m="20px"
                >
                  <Text
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontWeight="600"
                    fontSize="24px"
                    lineHeight="36px"
                    textAlign="left"
                    color="#021425"
                  >
                    Hey {subscriptionData?.user?.firstName}!
                  </Text>
                  <Text fontFamily="Poppins" fontStyle="normal" fontSize={"sm"}>
                    {subscriptionData?.user?.email}
                  </Text>
                  <Button
                    colorScheme="blue"
                    onClick={handleSignOut}
                    w={"100%"}
                    h={"56px"}
                    minH={"56px"}
                    color={"#F9FAFB"}
                    backgroundColor={"#0A64BC"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                  >
                    {"Log out"}
                  </Button>
                  <Button
                    colorScheme="whiteAlpha"
                    onClick={handleChangePassword}
                    fontFamily={"Poppins"}
                    w={"100%"}
                    h={"56px"}
                    minH={"56px"}
                    color="#0564B8"
                    backgroundColor={"white"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                  >
                    {"Change password"}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel key={"subscription"} tabIndex={1}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"20px"}
                  m="20px"
                >
                  <Text
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="36px"
                    textAlign="left"
                    color="#021425"
                  >
                    Manage Your subscription
                  </Text>
                  {renderComponent()}
                  {/* {(subscriptionData?.formattedSubscriptionPlan || 'free') ===
                    'free' ? (
                    renderComponent()
                  ) : (
                    <MembershipCard />
                  )} */}
                </Box>
              </TabPanel>
              <TabPanel key={"usage"} tabIndex={2}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"20px"}
                  m="20px"
                >
                  <Text
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="36px"
                    textAlign="left"
                    color="#021425"
                  >
                    Your usage of MonkStreet app in this month
                  </Text>

                  <RenderMetrics
                    metric={"numAnalysis"}
                    title={"Stocks Analysed"}
                    isMonthly
                  />
                  <RenderMetrics
                    metric={"numPortfolio"}
                    title={"Stocks in Portfolio"}
                    isMonthly
                  />
                  <RenderMetrics
                    metric={"numWatchlist"}
                    title={"Stocks in Watchlist"}
                    isMonthly
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </DesktopContent>
      <ChangePasswordModal />
    </MobileContent>
  );
}
