import axios from 'axios';
import { UseToastOptions } from '@chakra-ui/react';
import { clientApi } from '../services/client';

export interface Price {
  monthlyStandard: number;
  monthlyDiscounted: number;
  yearlyStandard: number;
  yearlyDiscounted: number;
  withMonthlyDiscount: boolean;
  withYearlyDiscount: boolean;
  yearlyTotal: number;
}

interface ProOrAdvancedOrBasic {
  title: string;
  name: string;
  price: Price;
  summary: string;
  optionsList?: string[] | null;
  bestValue: boolean;
}

export interface Price1 {
  monthlyStandard: number;
  monthlyDiscounted: number;
  yearlyStandard: number;
  yearlyDiscounted: number;
  monthlyDiscount?: null;
  withMonthlyDiscount: boolean;
  withYearlyDiscount: boolean;
  yearlyTotal?: null;
}

export interface Free {
  title: string;
  name: string;
  price: Price1;
  summary: string;
  optionsList?: string[] | null;
  bestValue: boolean;
}

type PlanType = ProOrAdvancedOrBasic | Free;

export const isAdvanced = (plan: PlanType) => plan?.name === "advanced";

export const isFree = (plan: PlanType) => plan?.name === "free";

export const isPro = (plan: PlanType) => plan?.name === "pro";

export const isBasic = (plan: PlanType) => plan?.name === "basic";

export const getSizes = (plan: PlanType) => {
  let imageSize = 180;
  let width = 287;

  if (isAdvanced(plan)) {
    imageSize = 270;
    width = 336;
  }

  if (isFree(plan)) {
    imageSize = 150;
    width = 280;
  }

  if (isBasic(plan)) {
    width = 294;
  }

  if (isPro(plan)) {
    width = 305;
  }
  return { width, imageSize };
};

interface CancelSubscriptionResult {
  success: boolean;
  message: string;
}

export const cancelSubscription = async (
  accessToken: string,
  toast: (options: UseToastOptions) => void,
  onSuccess?: () => void
): Promise<void> => {
  try {
    const authorization = (localStorage.getItem("accessToken")??"").replace(/['"]+/g, "").trim();
    
    const response = await clientApi.post(
      `/api/v1/payments/cancel-subscription`,
      {}
    );

    const result: CancelSubscriptionResult = {
      success: response.status === 200,
      message: response.status === 200 
        ? "Your membership has been successfully cancelled."
        : "Failed to cancel membership. Please try again later."
    };

    toast({
      title: result.success ? "Membership Cancelled" : "Error",
      description: result.message,
      status: result.success ? "success" : "error",
      duration: 5000,
      isClosable: true,
    });

    if (result.success && onSuccess) {
      onSuccess();
    }
  } catch (error) {
    console.error("Error cancelling membership:", error);
    toast({
      title: "Error",
      description: "Failed to cancel membership. Please try again later.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};
