import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { closeModal, selectModal } from "../../../store/slices/modal";
import PricingCards from "./pricing-cards";
import { clientApi } from "../../../services/client";

const getModalTitle = (type: string, num?: number) => {
  if (type === "analysis") {
    return {
      title: `You've reached your monthly analysis limit!`,
      subTitle: "Upgrade now to access unlimited features!",
    };
  }

  if (type === "portfolio") {
    let title = `You've reached your portfolio limit`;
    if (num === 0) {
      title = `Take Your Analysis Further`;
    }
    return {
      title: title,
      subTitle: "Upgrade now to access unlimited features",
    };
  }

  let title = `You've reached your watchlist limit!`;
  if (num === 0) {
    title = `Take Your Analysis Further`;
  }
  // for watchlist
  return {
    title,
    subTitle: "Upgrade now to access unlimited features!",
  };
};

export function PricingModal() {
  const modalData = useSelector(selectModal);
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeModal());
  const [modalType, setModalType] = useState("analysis");
  const [limit, setLimit] = useState<number>(30);
  const [isMonthly, setIsMonthly] = useState(false);
  const [loading, setLoading] = useState(-1);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", localStorage.getItem("accessToken") || "");

  useEffect(() => {
    setModalType(modalData?.link?.split("-")[0] || "analysis");
    setLimit(parseInt(modalData?.link?.split("-")[1] || "30"));
  }, [modalData]);

  const getCheckoutUrl = async (plan: string) => {
    let planIndex = {
      free: 3,
      basic: 2,
      advanced: 1,
      pro: 0,
    };
    const getProductName = () => {
      if (plan === "free") {
        return null;
      }
      if (plan === "basic") {
        return isMonthly ? "BASIC_MONTHLY" : "BASIC_YEARLY";
      }

      if (plan === "advanced") {
        return isMonthly ? "ADVANCED_MONTHLY" : "ADVANCED_YEARLY";
      }

      if (plan === "pro") {
        return isMonthly ? "PRO_MONTHLY" : "PRO_YEARLY";
      }
    };
    setLoading(planIndex[plan]);
    try {
      
      // make api call to get checkout url
      const resp = await clientApi.post(
        `/api/v1/payments/${getProductName()}`,
        {}
      );

      if (resp.status === 200) {
        console.log(`checkout url:${resp.data.data}`);
        // redirect to checkout url in a new tab
        window.open(resp.data.data, "_blank");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(-1);
    }
  };

  return (
    <Modal
      isOpen={modalData.isOpen && modalData?.type === MODAL_TYPES_ENUM.pricing}
      //size="6xl"
      scrollBehavior={"inside"}
      isCentered
      onClose={handleCloseModal}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={{ base: "95%", md: "100%", lg: "6xl" }}
      blockScrollOnMount={true}
    >
      <ModalOverlay sx={{ background: "rgba(4, 40, 75, 0.8)" }} />
      <ModalContent
        bg="#F9FaFB"
        borderRadius="20px"
        mx={5}
        p={"30px"}
        display="flex"
        py={{ base: "20px", md: 0 }}
      >
         <ModalHeader
          display="flex"
          alignItems="start"
          justifyContent={"justify-between"}
          w="full"
          zIndex={200}
        >
          <Box
            display={"flex"}
            rowGap={"14px"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={2}
          >
            {/* <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="600"
              fontSize="28px"
              lineHeight="36px"
              textAlign="left"
              color="#021425"
            >
              {`${getModalTitle(modalType, limit).title}`.toUpperCase()}
            </Text> */}
            <Text fontSize={"20px"} fontWeight={"light"}>
              {getModalTitle(modalType, limit).subTitle}
            </Text>
          </Box>
          <Box
            display={{ base: "none", md: "flex" }}
            bg="rgba(226, 232, 240, 1)"
            width="29.15px"
            height="29.15px"
            borderRadius="5px"
            gap="10px"
            p="8px"
            onClick={(e) => {
              e.preventDefault();
              handleCloseModal()
            }}
          >
            <svg
              style={{ cursor: "pointer" }}
              width="13"
              height="13"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.42461 8.475L2.52461 13.375C2.34128 13.5583 2.10794 13.65 1.82461 13.65C1.54128 13.65 1.30794 13.5583 1.12461 13.375C0.941276 13.1917 0.849609 12.9583 0.849609 12.675C0.849609 12.3917 0.941276 12.1583 1.12461 11.975L6.02461 7.075L1.12461 2.175C0.941276 1.99167 0.849609 1.75833 0.849609 1.475C0.849609 1.19167 0.941276 0.958333 1.12461 0.775C1.30794 0.591666 1.54128 0.5 1.82461 0.5C2.10794 0.5 2.34128 0.591666 2.52461 0.775L7.42461 5.675L12.3246 0.775C12.5079 0.591666 12.7413 0.5 13.0246 0.5C13.3079 0.5 13.5413 0.591666 13.7246 0.775C13.9079 0.958333 13.9996 1.19167 13.9996 1.475C13.9996 1.75833 13.9079 1.99167 13.7246 2.175L8.82461 7.075L13.7246 11.975C13.9079 12.1583 13.9996 12.3917 13.9996 12.675C13.9996 12.9583 13.9079 13.1917 13.7246 13.375C13.5413 13.5583 13.3079 13.65 13.0246 13.65C12.7413 13.65 12.5079 13.5583 12.3246 13.375L7.42461 8.475Z"
                fill="#667084"
              />
            </svg>
          </Box>
        </ModalHeader>
        {/* MONTHLY-YEARLY SWITCH */}
        <ModalBody
          overflow={{base:"auto",lg:"hidden"}}
          display="flex"
          flexDir="column"
          px="20px"
          rowGap={"30px"}
          mt={{base:0,lg:-4}}
          height={"100%"}
          borderRadius={"20px"}
          boxSizing="border-box"
          alignItems={"center"}
          fontFamily="Poppins"
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#0A64BC",
              borderRadius: "24px",
            },
          }}
        >
          <PricingCards
            isMonthly={isMonthly}
            setIsMonthly={setIsMonthly}
            showFree={false}
          />

          {/* <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"10px"}
            fontSize={"sm"}
          >
            <Text color={isMonthly ? "#0A64BC" : ""}>Billed Monthly</Text>
            <Switch
              size="lg"
              isChecked={!isMonthly}
              color={"#0A64BC"}
              onChange={() => setIsMonthly(!isMonthly)}
            />
            <Text color={!isMonthly ? "#0A64BC" : ""}>Billed yearly</Text>
          </Box>
          <Box
            display={{ base: "none", md: "flex" }}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"30px"}
          >
            {Object.values(billingPlans).map((plan, idx) => {
              let { imageSize, width } = getSizes(plan);
              return (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"15px"}
                  // minW={"200px"}
                  maxW={"250px"}
                  backgroundColor={plan.name === "advanced" ? "" : "#F9FAFB"}
                  style={{
                    background:
                      plan.name === "advanced"
                        ? "linear-gradient(to bottom,  #04284B, #085096)"
                        : "",
                  }}
                  color={plan.name === "advanced" ? "#FFFFFF" : ""}
                  p={"30px"}
                  borderRadius={"20px"}
                  boxSizing="content-box"
                >
                  <img
                    alt={`${plan?.title} plan image`}
                    src={`/images/${plan.name}_plan_bg.png`}
                    width={imageSize}
                    height={imageSize}
                    style={{
                      margin: "0 auto",
                      marginBottom: "12px",
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    gap="5px"
                    alignItems={"start"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"baseline"}
                    >
                      {!isMonthly && plan.name !== "free" && (
                        <Text
                          fontSize={"2xl"}
                          fontWeight={"bold"}
                          textDecoration={"line-through"}
                          marginRight={"5px"}
                          color={
                            plan.name === "advanced" ? "#0A64BC" : "#D3D5DA"
                          }
                        >
                          ${plan.price.monthlyStandard}
                        </Text>
                      )}
                      <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                        $
                        {isMonthly
                          ? plan.price.monthlyDiscounted
                          : plan.price.yearlyDiscounted}
                      </Text>
                      <Text fontSize={"sm"} fontStyle={"italic"} ml={"5px"}>
                        /month
                      </Text>
                    </Box>
                    {!isMonthly && plan.name !== "free" && (
                      <Text
                        fontStyle={"italic"}
                        fontSize={"smaller"}
                        color={plan.name === "advanced" ? "" : "#4D5461"}
                      >
                        billed as ${plan.price.yearlyTotal} per year
                      </Text>
                    )}
                  </Box>

                  <Text fontSize={"xl"} fontWeight={"bold"}>
                    {plan.title}
                  </Text>

                  <Text fontSize={"smaller"}>{plan.summary}</Text>

                  <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                    {plan.optionsList.map((option) => {
                      const [firstWord, secondWord, ...rest] =
                        option.split(" ");

                      return (
                        <Box
                          display="flex"
                          gap="5px"
                          alignItems={"start"}
                          className="option-item"
                          fontSize={"smaller"}
                        >
                          <Text fontWeight={"light"}>
                            <Text fontWeight={"semibold"}>
                              {firstWord} {secondWord}
                            </Text>{" "}
                            {rest.join(" ")}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>

                  <Button
                    colorScheme={plan.name === "free" ? "whiteAlpha" : "blue"}
                    w={"100%"}
                    h={"56px"}
                    minH={"56px"}
                    backgroundColor={plan.name !== "free" ? "#0A64BC" : "white"}
                    color={plan.name !== "free" ? "#F9FAFB" : "#0564B8"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                    onClick={() => getCheckoutUrl(plan.name)}
                  >
                    {loading === idx ? (
                      <Spinner mr="5px" />
                    ) : (
                      <>
                        {plan.name === "free" ? "Current Plan" : "Upgrade Plan"}
                      </>
                    )}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Box display={{ base: "flex", md: "none" }}>
            display tabs
            <Tabs>
              <TabList>
                {Object.values(billingPlans).map((plan) => (
                  <Tab key={plan.title} value={plan.name}>
                    {plan.title}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {Object.values(billingPlans).map((plan, idx) => {
                  let { imageSize, width } = getSizes(plan);

                  return (
                    <TabPanel key={plan.title} tabIndex={idx}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"15px"}
                        minW={"200px"}
                        maxW={"250px"}
                        backgroundColor={
                          plan.name === "advanced" ? "" : "#F9FAFB"
                        }
                        style={{
                          background:
                            plan.name === "advanced"
                              ? "linear-gradient(to bottom,  #04284B, #085096)"
                              : "",
                        }}
                        color={plan.name === "advanced" ? "#FFFFFF" : ""}
                        p={"30px"}
                        borderRadius={"20px"}
                        boxSizing="content-box"
                      >
                        <img
                          alt={`${plan?.title} plan image`}
                          src={`/images/${plan.name}_plan_bg.png`}
                          width={imageSize}
                          height={imageSize}
                          style={{
                            margin: "0 auto",
                            marginBottom: "12px",
                          }}
                        />
                        <Box
                          display="flex"
                          flexDirection={"column"}
                          gap="5px"
                          alignItems={"start"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"baseline"}
                          >
                            {!isMonthly && plan.name !== "free" && (
                              <Text
                                fontSize={"2xl"}
                                fontWeight={"bold"}
                                textDecoration={"line-through"}
                                marginRight={"5px"}
                                color={
                                  plan.name === "advanced"
                                    ? "#0A64BC"
                                    : "#D3D5DA"
                                }
                              >
                                ${plan.price.monthlyStandard}
                              </Text>
                            )}
                            <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                              $
                              {isMonthly
                                ? plan.price.monthlyDiscounted
                                : plan.price.yearlyDiscounted}
                            </Text>
                            <Text
                              fontSize={"sm"}
                              fontStyle={"italic"}
                              ml={"5px"}
                            >
                              /month
                            </Text>
                          </Box>
                          {!isMonthly && plan.name !== "free" && (
                            <Text
                              fontStyle={"italic"}
                              fontSize={"smaller"}
                              color={plan.name === "advanced" ? "" : "#4D5461"}
                            >
                              billed as ${plan.price.yearlyTotal} per year
                            </Text>
                          )}
                        </Box>

                        <Text fontSize={"xl"} fontWeight={"bold"}>
                          {plan.title}
                        </Text>

                        <Text fontSize={"smaller"}>{plan.summary}</Text>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"8px"}
                        >
                          {plan.optionsList.map((option) => {
                            const [firstWord, secondWord, ...rest] =
                              option.split(" ");

                            return (
                              <Box
                                display="flex"
                                gap="5px"
                                alignItems={"start"}
                                className="option-item"
                                fontSize={"smaller"}
                              >
                                <Text fontWeight={"light"}>
                                  <Text fontWeight={"semibold"}>
                                    {firstWord} {secondWord}
                                  </Text>{" "}
                                  {rest.join(" ")}
                                </Text>
                              </Box>
                            );
                          })}
                        </Box>

                        <Button
                          colorScheme={
                            plan.name === "free" ? "whiteAlpha" : "blue"
                          }
                          w={"100%"}
                          h={"56px"}
                          minH={"56px"}
                          backgroundColor={
                            plan.name !== "free" ? "#0A64BC" : "white"
                          }
                          color={plan.name !== "free" ? "#F9FAFB" : "#0564B8"}
                          borderRadius={"8px"}
                          fontSize={"16px"}
                          lineHeight={"24px"}
                          fontWeight={600}
                          fontFamily={"Poppins"}
                        >
                          {plan.name === "free"
                            ? "Current Plan"
                            : "Upgrade Plan"}
                        </Button>
                      </Box>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </Box> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
