import { jwtDecode, JwtPayload } from "jwt-decode";

export function decodeUserToken(token: string) {
    if(!token)return null
    try {
        const decoded = jwtDecode<JwtPayload & {email:string}>(token);
        console.log(decoded);
        return decoded
    } catch (error) {
        console.log("Error when decoded token")
        console.error(error)
    }
    
  }