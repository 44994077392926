import { Box, useBreakpoint } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import CustomNegativeChart from "../../common/Charts/CustomNegativeChart/CustomNegativeChart";

export interface CandlestickChatProps {
  lessCurved?: boolean;
  activeChart?: string;
  altColor?: boolean;
  data: any[];
  sign?: any;
  multiplier?: number
}

const isReverse = (activeChart: string | undefined) => {
  const reverseCharts = [
    "Net Debt to Equity",
    "Cash Conversion Cycle",
    "Asset Growth",
    "External Financing Ratio",
    "Percent Accruals",
    "Cap. Exp. Growth",
    "Price / Sale",
    "Price / Earnings",
    "P/FCF",
    "Price / Earnings Growth",
    "EV / Revenue",
    "EV / Operating Earnings",
    "EV / EBITDA",
    "EV / FCF",
  ];

  return reverseCharts.includes(activeChart ?? "");
};

const CandlestickChart: FC<CandlestickChatProps> = ({
  data,
  lessCurved,
  activeChart,
  altColor,
  sign,
  multiplier = 1
}) => {
  const intl = useIntl();

  const breakpoint = useBreakpoint();
  const [hoverIndex, setHoverIndex] = useState<string>();
  const [position, setPosition] = useState({
    data: { x: 0, y: 0 },
    show: false,
  });

  const dataArr = [...data]?.map((value, index) =>
    Number(
      intl.formatNumber(Math.round(value?.value * 100) || 0, {
        notation: "compact",
      }),
    ),
  );

  const isMobile = breakpoint === "base";

  let thresholdValue = activeChart === "Earning Quality" ? 0.1 : 0;

  const formattedData = (data: any) => {
    if (data.length === 0) return [];

    return data.map((item, i) => {
      return {
        fullDate: `${item.yearQ}-${item.period}`,
        date: item.yearQ,
        percentile: item.value,
        period: item.period,
      };
    });
  };

  return (
    <Box paddingX={"10px"} paddingY={"30px"}>
      <CustomNegativeChart
        initialData={formattedData(data)}
        reverse={isReverse(activeChart)}
        activeChart={activeChart}
        sign={sign}
        multiplier={multiplier}
      />
    </Box>
  );
};

export default CandlestickChart;
