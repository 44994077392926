import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./feedback.css";

const NavigationFeedback = () => {
    const [isNavigating, setIsNavigating] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let timeoutId;

        // Complete navigation feedback
        const completeNavigation = () => {
            setProgress(100);
            setShowOverlay(true);

            // Cleanup after animation
            timeoutId = setTimeout(() => {
                setShowOverlay(false);
                setIsNavigating(false);
                //setProgress(0);
            }, 400);
        };

        // Start navigation feedback
            const startNavigation = () => {
                setIsNavigating(true);
                setProgress(0);
                setTimeout(() => setProgress(60), 50);  // Quick initial jump
                setTimeout(() => setProgress(80), 150); // Slower to 80%
                setTimeout(() => setProgress(95), 300); // Very slow to 95%
                setTimeout(() => completeNavigation(), 500); // Complete
            };

        

        // Watch for changes in `location.pathname` to trigger the feedback
        startNavigation();
        

        return () => {
            clearTimeout(timeoutId);
        };
    }, [location, navigate]);

    return (
        <>

            {
                isNavigating && (
                    <div className="feedback-progress-bar-container">
                        <div
                            className="feedback-progress-bar"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                )
            }
        </>
    );
};

export default NavigationFeedback;
