import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { closeModal, selectModal } from "../../../store/slices/modal";
import PricingCards from "./pricing-cards";
import { clientApi } from "../../../services/client";


export function AfterLoginPricingModal({
  open,
  handleCloseModal
}: {open: boolean,handleCloseModal: () => void}) {

  const dispatch = useDispatch();
 
  const [modalType, setModalType] = useState("analysis");
  const [limit, setLimit] = useState<number>(30);
  const [isMonthly, setIsMonthly] = useState(false);
  const [loading, setLoading] = useState(-1);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", localStorage.getItem("accessToken") || "");

  
  const getCheckoutUrl = async (plan: string) => {
    let planIndex = {
      free: 3,
      basic: 2,
      advanced: 1,
      pro: 0,
    };
    const getProductName = () => {
      if (plan === "free") {
        return null;
      }
      if (plan === "basic") {
        return isMonthly ? "BASIC_MONTHLY" : "BASIC_YEARLY";
      }

      if (plan === "advanced") {
        return isMonthly ? "ADVANCED_MONTHLY" : "ADVANCED_YEARLY";
      }

      if (plan === "pro") {
        return isMonthly ? "PRO_MONTHLY" : "PRO_YEARLY";
      }
    };
    setLoading(planIndex[plan]);
    try {
      
      // make api call to get checkout url
      const resp = await clientApi.post(
        `/api/v1/payments/${getProductName()}`,
        {}
      );

      if (resp.status === 200) {
        console.log(`checkout url:${resp.data.data}`);
        // redirect to checkout url in a new tab
        window.open(resp.data.data, "_blank");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(-1);
    }
  };

  return (
    <Modal
      isOpen={open}
      size="5xl"
      scrollBehavior={"inside"}
      isCentered
      onClose={handleCloseModal}
      blockScrollOnMount={true}
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#FFF"
        borderRadius="20px"
        my={140}
        mx={5}
        p={"20px"}
        display="flex"
        // make the scrollbar narrow
      >
        {/* MONTHLY-YEARLY SWITCH */}
        <ModalBody
          display="flex"
          flexDir="column"
          py={"40px"}
          px="20px"
          rowGap={"30px"}
          mt={0}
          borderRadius={"20px"}
          boxSizing="border-box"
          alignItems={"center"}
          fontFamily="Poppins"
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#0A64BC",
              borderRadius: "24px",
            },
          }}
        >
          <Box
            display={"flex"}
            rowGap={"14px"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="600"
              fontSize="28px"
              lineHeight="36px"
              textAlign="left"
              color="#021425"
            >
              {`Manage your subscription`.toUpperCase()}
            </Text>
            
          </Box>
          <PricingCards
            isMonthly={isMonthly}
            setIsMonthly={setIsMonthly}
            showFree={false}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
