import { createSlice } from "@reduxjs/toolkit";

type TModalAction = {
  type: string;
  payload: {
    name: string;
  };
};

type ShareAction = {
  payload: {
    activeChart?: string;
  elemetId?: string;
  isChart?: boolean
  };
};

type TModal = {
  isOpen: boolean;
  name: string;
  activeChart?: string;
  elemetId?: string;
  isChart?: boolean
};

const initialState: TModal = {
  isOpen: false,
  name: "",
  activeChart: undefined,
  elemetId: undefined,
  isChart: false
}

const modalShareSlice = createSlice({
  name: "modalShare",
  initialState,
  reducers: {
    openModal: (state, action: TModalAction) => {
      state.name = action.payload?.name;
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.name = "";
      state.isOpen = false;
    },
    setShareOptions: (state, action: ShareAction) => {
      state.activeChart = action.payload?.activeChart;
      state.elemetId = action.payload?.elemetId;
      state.isChart = action.payload?.isChart;
    },
  },
});

export const { openModal, closeModal,setShareOptions } = modalShareSlice.actions;

export const selectIsModalOpen = (state) => state.modalShare.isOpen;
export const selectModal = (state) => state.modalShare as TModal;

export default modalShareSlice.reducer;
