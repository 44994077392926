import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { FaCcVisa, FaCreditCard } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useUpdatePaymentMethodMutation } from "../../services/paymentApi";
import { useFetchSubscriptionDataQuery } from "../../services/userApi";

interface PaymentMethodProps {
  card: {
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
}

export const PaymentMethodStripe = ({ card }: PaymentMethodProps) => {
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [accessToken] = useLocalStorage("accessToken", "");
  const toast = useToast();
  const { refetch } = useFetchSubscriptionDataQuery(accessToken);
  const [paymentMethod, setPaymentMethod] = useState<string>("credit-card");
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPaymentMethod(event.target.value);
  };

  const handleUpdatePaymentMethod = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      toast({
        title: "Error",
        description: "Stripe has not been initialized",
        status: "error",
        duration: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error("Card element not found");
      }

      // Create payment method using Stripe
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (!paymentMethod) {
        throw new Error("Failed to create payment method");
      }

      const result = await updatePaymentMethod(paymentMethod.id).unwrap();

      if (result.success) {
        // Clear form
        cardElement.clear();
        setPaymentMethod("credit-card");

        // Show success message
        toast({
          title: "Success",
          description: "Payment method updated successfully",
          status: "success",
          duration: 3000,
        });

        // Refresh subscription data to update card display
        await refetch();
      } else {
        throw new Error(result.message || "Failed to update payment method");
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to update payment method",
        status: "error",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="6"
      boxShadow="lg"
    >
      <Stack spacing={4}>
        <Heading size="md">Payment Method</Heading>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Current Card:
          </Text>

          <Badge
            colorScheme="white"
            px="4"
            py="2"
            borderRadius="full"
            borderWidth="1px"
            borderColor="gray.300"
          >
            <Flex alignItems="center">
              {card.brand === "visa" ? (
                <FaCcVisa
                  color="#002A7A"
                  size="20px"
                  style={{ marginRight: "8px" }}
                />
              ) : card.brand === "mastercard" ? (
                <RiMastercardFill
                  color="#EB001B"
                  size="20px"
                  style={{ marginRight: "8px" }}
                />
              ) : (
                <FaCreditCard
                  color="#000"
                  size="20px"
                  style={{ marginRight: "8px" }}
                />
              )}
              {card.brand.toUpperCase()} - {card.last4}
            </Flex>
          </Badge>
        </Flex>
        {/* {card.map((item: any, index) => {
          return (
            <Flex alignItems="center" justifyContent="space-between" key={index}>
              {index === 0 ? (
                <Text fontSize="lg" fontWeight="bold">
                  Default Card:
                </Text>
              ) : (
                <Text fontSize="lg" fontWeight="bold" opacity={0}>
                  Default Card:
                </Text>
              )}
              <Badge
                colorScheme="white"
                px="4"
                py="2"
                borderRadius="full"
                borderWidth="1px"
                borderColor="gray.300"
              >
                <Flex alignItems="center">
                  {item.brand === "visa" ? (
                    <FaCcVisa
                      color="#002A7A"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  ) : item.brand === "mastercard" ? (
                    <RiMastercardFill
                      color="#EB001B"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <FaCreditCard
                      color="#000"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {item.brand.toUpperCase()} - {item.last4}
                </Flex>
              </Badge>
            </Flex>
          );
        })} */}

        <Box>
          <Text>Select New Payment Method:</Text>
          <Select
            placeholder="Select payment method"
            mt="2"
            onChange={handlePaymentMethodChange}
          >
            <option value="credit-card">Credit Card</option>
            <option value="debit-card">Debit Card</option>
            {/* <option value="paypal">PayPal</option>
            <option value="apple-pay">Apple Pay</option> */}
          </Select>
        </Box>

        {/* Conditionally render Stripe CardElement for credit/debit cards */}
        {(paymentMethod === "credit-card" ||
          paymentMethod === "debit-card") && (
          <Box mt="4">
            <FormControl>
              <FormLabel>Card Information</FormLabel>
              <Box borderWidth="1px" borderRadius="md" p="2">
                <CardElement />
              </Box>
            </FormControl>
          </Box>
        )}

        <Button
          isLoading={isLoading}
          loadingText="Updating..."
          colorScheme="teal"
          mt="4"
          onClick={handleUpdatePaymentMethod}
          disabled={!stripe}
        >
          Update Payment Method
        </Button>
      </Stack>
    </Box>
  );
};
