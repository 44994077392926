import { Box, Text, Progress } from "@chakra-ui/react";

export const RenderMetrics = ({
  subscriptionData,
  metric,
  defaults = [0, 5],
  title,
}: {
  subscriptionData: any
  title: string;
  metric: string;
  defaults: number[];
}) => {
  const metricMap = {
    numAnalysis: "analysis",
    numPortfolio: "portfolio",
    numWatchlist: "watchlist",
  };
  const numData =
    subscriptionData && Object.keys(subscriptionData?.usage || {}).includes(metric)
      ? (subscriptionData?.usage[metric]?.used ?? defaults[0])
      : defaults[0];

  const totalData =
    subscriptionData && Object.keys(subscriptionData?.usage  || {}).includes(metric)
      ? (subscriptionData?.usage[metric]?.limit ?? defaults[1])
      : defaults[1];

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"10px"}
      justifyItems={"start"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={"center"}
      >
        <Text fontFamily={"Poppins"} fontSize={"14px"} lineHeight={"20px"}>{title}</Text>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"baseline"}
          gap={"10px"}
          fontFamily={"Poppins"} fontSize={"14px"} lineHeight={"20px"}
        >
          <Text fontFamily={"Poppins"} fontSize={"14px"} lineHeight={"20px"}>{numData}/ {totalData}</Text>
        </Box>
      </Box>

      <Progress
        value={totalData !== 0 ? (numData * 100) / totalData : 0}
        max={100}
        size="md"
        colorScheme="blue"
        width="100%"
        borderRadius={"md"}
      />
    </Box>
  );
};