import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface SubscriptionDetailsProps {
  onChangePlan: () => void;
  onManagePaymentMethod: () => void;
  onViewPaymentHistory: () => void;
  onCancelMembership: () => void;
  subscriptionData?: {
    subscription: {
      plan: string;
      currentPeriodStart: number;
      currentPeriodEnd: number;
      cancelAtPeriodEnd: boolean;
      status: string;
    };
  };
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  onChangePlan,
  onManagePaymentMethod,
  onViewPaymentHistory,
  onCancelMembership,
  subscriptionData,
}) => {
  const renderButton = (label: string, onClick: () => void) => (
    <>
      <Button
        variant="link"
        onClick={onClick}
        justifyContent="space-between"
        textAlign="left"
        color="black"
        rightIcon={<ChevronRightIcon />}
        width="100%"
        _hover={{ textDecoration: "none" }}
      >
        {label}
      </Button>
      <Divider />
    </>
  );

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getPlanDisplay = (plan: string) => {
    const [name] = plan.split("_");
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  return (
    <Box
      maxWidth="400px"
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      pt={12}
      position="relative"
    >
      <Box
        bg="blue.600"
        color="white"
        p={2}
        my={2}
        borderRadius="md"
        borderLeftRadius={0}
        position="absolute"
        left={0}
        top={0}
      >
        <Text fontSize="sm">
          Member since{" "}
          {subscriptionData?.subscription
            ? formatDate(subscriptionData.subscription.currentPeriodStart)
            : "N/A"}
        </Text>
      </Box>
      <VStack spacing={4} align="stretch">
        <Text fontWeight="bold" fontSize="xl">
          Plan:{" "}
          {subscriptionData?.subscription
            ? getPlanDisplay(subscriptionData.subscription.plan)
            : "Free"}
        </Text>
        <Text>
          Next payment:{" "}
          {subscriptionData?.subscription
            ? formatDate(subscriptionData.subscription.currentPeriodEnd)
            : "N/A"}
        </Text>
        {renderButton("Change plan", onChangePlan)}
        {renderButton("Manage payment method", onManagePaymentMethod)}
        {renderButton("View payment history", onViewPaymentHistory)}
        <Button
          colorScheme="red"
          variant="outline"
          onClick={onCancelMembership}
          color="#8a4542"
          isDisabled={
            !subscriptionData?.subscription ||
            subscriptionData.subscription.cancelAtPeriodEnd
          }
        >
          {subscriptionData?.subscription?.cancelAtPeriodEnd
            ? "Cancellation Scheduled"
            : "Cancel Membership"}
        </Button>
      </VStack>
    </Box>
  );
};

export default SubscriptionDetails;
