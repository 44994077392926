export const ratioDictSign = {
  //PROFITABILITY FIRST GROUP
  "Gross Margin": "%",
  "Operating Margin": "%",
  "Net Profit Margin": "%",
  "FCF Margin": "%",

  //PROFITABILITY SECOND GROUP
  ROIC: "%",
  ROE: "%",
  ROA: "%",
  ROCE: "%",

  //HEALTH FIRST GROUP
  "Net Debt to Equity": " ",
  "Interest Coverage": " ",
  "Current Ratio": " ",
  "Quick Ratio": " ",

  //HEALTH SECOND GROUP
  "Cash Conversion Cycle": "days",
  'cashConversionCycle': 'days',
  'dso': 'days',
  'dpo': 'days',
  "Asset Growth": "%",
  "External Financing Ratio": "%",
  "Percent Accruals": "%",

  //SHAREHOLDER RETRIBUTION FIRST GROUP
  "Dividend Yield": "%",
  "Buyback Ratio": "%",
  "Debt Paydown": "%",
  "Shareholder Yield": "%",

  //GROWTH FIRST GROUP
  "Revenue Growth": "%",
  "Operating Income Growth": "%",
  "Operating Cash Flow Growth": "%",
  "Capital Expenditures Growth": "%",
  "Free Cash Flow Growth": "%",
  "Oper. Income Growth": "%",
  "Oper. Cash Flow Growth": "%",
  "Cap. Exp. Growth": "%",


  //GROWTH SECOND GROUP
  "Growth Trend": "%",
  "Growth Predictability": "%",
  "Growth Sustainability": " ",
  Momentum: "%",

  //VALUATION FIRST GROUP
  "Price / Sale": " ",
  "Price / Earnings": " ",
  "P/FCF": " ",
  "Price / Earnings Growth": " ",

  //VALUATION SECOND GROUP
  "EV / Revenue": " ",
  "EV / Operating Earnings": " ",
  "EV / EBITDA": " ",
  "EV / FCF": " ",

  // UNKNOWN FIELDS
  "GP/Assets": "%",
  "Probability of Default": "%",
  "EV/Operating Income": "%",
  "EV/Net Income": "%",
  "EV/Assets": "%",
  "EV/Operating Income Growth": "%",
  FCF: "%",
  "Net Income": "%",
  Revenue: "%",
  'revenue': { type: 'currency', currency: 'USD' },
  'marketCap': { type: 'currency', currency: 'USD' },
  'freeCashFlow': { type: 'currency', currency: 'EUR' },
  "Operating Income": "%",
  "Operating Cash Flow": "%",
  "Capital Expenditure": "%",
};
