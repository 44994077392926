import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { Dispatch, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import "../../App.css";
import Eye from "../../assets/svg/Eye.png";
import Name from "../../assets/svg/name.svg";
import Eyeshow from "../../assets/svg/Eyeshow.svg";
import Lock from "../../assets/svg/Lock.svg";
import Mail from "../../assets/svg/Mail.svg";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { useForgotPasswordMutation } from "../../services/userApi";
import { openModal as openModalDispatch } from "../../store/slices/modal";
import { setActualUser } from "../../store/slices/user";
import { last } from "lodash";

const SigninSignupWithCredentialsModal = ({
  openModal,
  setOpenModal,
  signinClicked,
  setSigninClicked,
  fetchLoginStatus,
  directSignupWithCredentials = false,
  userInput,
  setUserInput,
  TCChecked,
  setOpenTCModal,
  sendPeriodicUpdates,
  onClose,
}: {
  openModal: boolean;
  setOpenModal: Dispatch<boolean>;
  signinClicked: boolean;
  setSigninClicked: Dispatch<boolean>;
  fetchLoginStatus: any;
  directSignupWithCredentials?: boolean;
  userInput: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  };
  setUserInput: Dispatch<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>;
  TCChecked: boolean;
  setOpenTCModal: Dispatch<boolean>;
  sendPeriodicUpdates: boolean;
  onClose?: () => void;
}) => {
  const [showForm, setShowForm] = useState(
    directSignupWithCredentials || false,
  );

  const [emailFocused, setEmailFocused] = useState(
    userInput?.email ? true : false,
  );
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [fNameFocused, setFNameFocused] = useState(false);
  const [lNameFocused, setLNameFocused] = useState(false);
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userMessage, setUserMessage] = useState({ msg: "", color: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [showForgotPwd, setShowForgotPwd] = useState(false);
  const dispatch = useDispatch();
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const [error, setError] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [forgotPassword, { isLoading: isForgotPasswordLoading }] =
    useForgotPasswordMutation();

  const isError = error.email != "" || error.password != "" || error.firstName != "";

  useEffect(() => {
    userInput?.email
      ? setEmailFocused(true)
      : userInput?.password && setPasswordFocused(true);
  }, [userInput]);

  useEffect(() => {
    if (showForm) setUserMessage({ msg: "", color: "" });
  }, [showForm]);
  useEffect(() => {
    if (signinClicked) setUserMessage({ msg: "", color: "" });
  }, [signinClicked]);
  useEffect(() => {
    if (showForgotPwd) setUserMessage({ msg: "", color: "" });
  }, [showForgotPwd]);

  const resetState = () => {
    setPasswordFocused(false);
    setFNameFocused(false);
    setLNameFocused(false);
    setShow(false);
    setShowLoader(false);
    setUserMessage({ msg: "", color: "" });
    setIsChecked(false);
    setShowForgotPwd(false);
  }

  async function verifyEmail(email) {
    // Skip disposable email check in local mode
    if (process.env.REACT_APP_MODE === "LOCAL" || "DEVELOPMENT") {
      return "valid";
    }

    const response = await axios.get(
      `https://emailverifier.reoon.com/api/v1/verify`,
      {
        params: {
          email: email,
          key: process.env.REACT_APP_REOON_API_KEY,
          mode: "quick",
        },
      },
    );

    if (response.data) {
      return response.data.status;
    } else {
      return "invalid";
    }
  }

  const handleValidation = (emailVerificationStatus = "valid") => {
    let hasErrors = false
    let error = { email: "", password: "", firstName: "", lastName: "" };


    if (userInput?.email !== "") {
      if (!userInput?.email?.match(emailRegex)) {
        error.email = "Invalid email address.";
        hasErrors = true
      } else if (emailVerificationStatus === "invalid") {
        error.email = "Check the email address provided.";
        hasErrors = true
      } else if (emailVerificationStatus === "disposable") {
        error.email = "Disposable emails are not allowed.";
        hasErrors = true
      }
    } else {
      error.email = "Email is required.";
      hasErrors = true
    }

    if (userInput?.password !== "") {
      if (userInput?.password?.length < 8) {
        error.password = "Password should have at least 8 character.";
        hasErrors = true
      }
    } else {
      error.password = "Password  is required.";
      hasErrors = true
    }

    if (!signinClicked && userInput?.firstName.length === 0) {
      error.firstName = "First Name is required.";
      hasErrors = true
    }else if(!/^(?!\s)(?!\s*$).+/.test(userInput?.firstName) && !signinClicked){
      error.firstName = "First Name is required.";
      hasErrors = true
    }else{
      error.firstName = "";
    }

    if(!/^(?!\s)(?!\s*$).+/.test(userInput?.lastName) && userInput?.lastName.length > 0 && !signinClicked){
      error.lastName = "Last name cannot contain whitespace before the first character";
      hasErrors = true
    }else{
      error.lastName = "";
    }

    setError(error);
    return hasErrors
  };

  const handleUserSigninPostSignup = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
      { ...userInput, flag: "USING_EMAIL" },
    );
    if (
      response?.status === 200 &&
      response?.data?.message === "login successfully"
    ) {
      setShowLoader(false);
      localStorage.setItem("accessToken", response?.data?.data?.token);
      localStorage.setItem("usr", JSON.stringify(response?.data?.data?.user));
      dispatch(setActualUser(response?.data?.data?.user));
      fetchLoginStatus(response?.data?.data?.token);
      setShowForm(false);
      setSigninClicked(false);
      setOpenModal(false);
    }
  };

  const handleUserSignin = async () => {
    setError({ email: "", password: "", firstName: "",lastName: "", });
    if (
      userInput?.email?.match(emailRegex) &&
      userInput?.password?.length >= 8
    ) {
      setShowLoader(true);
      setUserMessage({ msg: "", color: "" });
      
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
        { ...userInput, flag: "USING_EMAIL" },
      );
      if (
        response?.status === 200 &&
        response?.data?.message === "user doesnot exist"
      ) {
        setShowLoader(false);
        setShowForm(true);
        //setSigninClicked(false);
        const message = response?.data?.message === "user doesnot exist" ? "We couldn't find an account associated with the provided email address. Please double-check the email or consider creating a new account." : response?.data?.message
        setUserMessage({ msg: message, color: "red" });
        setUserInput({ email: "", password: "", firstName: "", lastName: "" });
        dispatch(setActualUser(response?.data?.data?.user));
      } else if (
        response?.status === 200 &&
        response?.data?.message === "incorrect password"
      ) {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(true);
        setError({ email: "", password: "", firstName: "",lastName: "", });
        setError({
          email: "",
          password: response?.data?.message,
          firstName: "",
          lastName: "",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "login successfully"
      ) {
        setShowLoader(false);
        localStorage.setItem("accessToken", response?.data?.data?.token);
        localStorage.setItem("usr", JSON.stringify(response?.data?.data?.user));
        dispatch(setActualUser(response?.data?.data?.user));
        fetchLoginStatus(response?.data?.data?.token);
        setShowForm(false);
        setSigninClicked(false);
        setOpenModal(false);
        if (onClose) {
          onClose();
        }
      } else {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(false);
        setError({ email: "", password: "", firstName: "",lastName: "", });
        setUserMessage({ msg: response?.data?.message, color: "red" });
      }
    } else {
      handleValidation();
    }
  };

  const handleUserSignup = async () => {
    setError({ email: "", password: "", firstName: "",lastName: "", });

    const emailVerificationStatus = await verifyEmail(userInput?.email);

    if (
      emailVerificationStatus !== "valid" ||
      !userInput?.email?.match(emailRegex) ||
      userInput?.password?.length < 8
    ) {
      handleValidation(emailVerificationStatus);
      return;
    }

    setShowLoader(true);
    setUserMessage({ msg: "", color: "" });
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
      {
        ...userInput,
        flag: "USING_EMAIL",
        sendPeriodicUpdates: sendPeriodicUpdates,
      },
    );

    if (
      response?.status === 200 &&
      response?.data?.message === "signup successfully"
    ) {
      await axios.post(
        `https://cloud.activepieces.com/api/v1/webhooks/D30Izk60RHNZ0npFdw4xe?name=${userInput.firstName}&email=${userInput.email}`,
      );
      setShowLoader(false);
      setShowForm(false);
      setSigninClicked(true);
      handleUserSigninPostSignup();
      setError({ email: "", password: "", firstName: "",lastName: "", });
    } else {
      setShowLoader(false);
      setSigninClicked(true);
      setError({ email: "", password: "", firstName: "",lastName: "", });
      setUserMessage({ msg: response?.data?.message, color: "red" });
    }
  };

  useEffect(() => {
    if (!signinClicked && TCChecked && userInput.email) {
      handleUserSignup();
    }
  }, [TCChecked, signinClicked, userInput.email]);

  const handleForgotPassword = async () => {
    if (!userInput.email?.match(emailRegex)) {
      setUserMessage({
        msg: "Please enter a valid email address",
        color: "red",
      });
      return;
    }

    try {
      setShowLoader(true);
      const result = await forgotPassword({ email: userInput.email }).unwrap();

      if (!result.success) {
        setUserMessage({ msg: result.message, color: "red" });
      } else {
        dispatch(
          openModalDispatch({
            name: "ResetPassword",
            link: userInput.email,
            type: MODAL_TYPES_ENUM.resetPassword,
          }),
        );
        setOpenModal(false);
      }
    } catch (error) {
      setUserMessage({
        msg: "Something went wrong. Try again",
        color: "red",
      });
    } finally {
      setShowLoader(false);
    }
  };

  /* useEffect(() => {
    if (userInput?.email !== "") {
      if (!userInput?.email?.match(emailRegex)) {
        console.log("Enter in validation modal")
        error.email = "Invalid email address.";
      } else {
        error.email = "";
      }
    }
  }, [userInput]) */


  return openModal ? (
    <Modal onOverlayClick={()=>{
      //resetState()
      setOpenModal(false)
      onClose && onClose()
    }} isOpen={openModal} onClose={() => {
      //resetState()
      setOpenModal(false)
    }} blockScrollOnMount={true}>
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        my={140}
        mx={5}
        background="#FFF"
        borderRadius="20px"
        p={"20px"}
      >
        {showForgotPwd ? (
          <ModalBody
            py={"40px"}
            px="20px"
            backgroundColor={"#F9FAFB"}
            borderRadius={"20px"}
            mt={0}
          >
            <Text
              fontSize="24px"
              lineHeight="36px"
              textAlign="left"
              fontFamily="Poppins"
              fontWeight={600}
              mb={6}
            >
              {"Forgot your password?"}
            </Text>

            <Text
              fontSize="14px"
              fontFamily="Poppins"
              lineHeight="20px"
              textAlign="center"
              fontWeight={400}
              color={userMessage?.color}
              mb={6}
            >
              {userMessage?.msg}
            </Text>

            <>
              <InputGroup my="14px" position="relative" justifyItems={"center"}>
                <InputLeftElement py="27px" ms={1}>
                  <img src={Mail} alt="" />
                </InputLeftElement>
                <Input
                  focusBorderColor="#D3D5DA"
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(userInput.email ? true : false)}
                  onChange={(e) =>
                    setUserInput({ ...userInput, email: e.target.value })
                  }
                  value={userInput?.email}
                  backgroundColor="white"
                  pt="33px"
                  pb="13px"
                  height="55px"
                  fontWeight={400}
                  fontSize="16px"
                  borderRadius="16px"
                  fontFamily="Poppins"
                  border={0}
                  placeholder=" "
                  type="text"
                />
                <FormLabel
                  position="absolute"
                  fontFamily="Poppins"
                  top={emailFocused ? "1px" : 3}
                  color="#9EA3AE"
                  fontWeight={400}
                  fontSize={emailFocused ? "12px" : "16px"}
                  left={10}
                  zIndex={2}
                  cursor="text"
                  onClick={() => setEmailFocused(true)}
                >
                  <FormattedMessage id="emailLabel" />
                </FormLabel>
              </InputGroup>
              <Text
                color="red"
                fontSize="14px"
                fontWeight={400}
                ms={3}
                fontFamily="Poppins"
              >
                {error?.email}
              </Text>

              <Button
                textAlign="center"
                py="28px"
                width="100%"
                fontFamily="Poppins"
                color="white"
                borderRadius="8px"
                backgroundColor="#0A64BC"
                boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                my={"14px"}
                cursor="pointer"
                _hover={{ backgroundColor: "#0A64BC" }}
                onClick={() => handleForgotPassword()}
              >
                <Text
                  fontWeight={600}
                  fontSize="16px"
                  fontFamily="Poppins"
                  lineHeight="24px"
                >
                  {showLoader ? <Spinner /> : "Reset my password"}
                </Text>
              </Button>
            </>

            <Text
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              textAlign="center"
              fontFamily="Poppins"
              color="#0A64BC"
              cursor="pointer"
              mt={6}
              onClick={() => {
                setShowForm(true);
                setShowForgotPwd(false);
              }}
            >
              Take me back to login
            </Text>
          </ModalBody>
        ) : (
          <ModalBody
            py={"40px"}
            px="20px"
            backgroundColor={"#F9FAFB"}
            borderRadius={"20px"}
            mt={0}
            as={"form"}
            onKeyDown={(e) => {
              console.log(e)
              if(e.code === "Enter"){
                const actualErrors = handleValidation()
                  if(!actualErrors){
                    signinClicked ? handleUserSignin() : setOpenTCModal(true)
                  }else{
                    console.log("hay error")
                    console.log(error)
                  }
              }
            }}
            onSubmit={(e) => {
              console.log(e)
              
                const actualErrors = handleValidation()
                  if(!actualErrors){
                    signinClicked ? handleUserSignin() : setOpenTCModal(true)
                  }else{
                    console.log("hay error")
                    console.log(error)
                  }
              
            }}
          >
            <Text
              fontSize="24px"
              lineHeight="36px"
              textAlign="left"
              fontFamily="Poppins"
              fontWeight={600}
              mb={6}
            >
              {signinClicked
                ? "Enter your email and password"
                : "Enter your email and a password"}
            </Text>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              lineHeight="20px"
              textAlign="center"
              fontWeight={400}
              color={userMessage?.color}
              mb={3}
            >
              {userMessage?.msg}
            </Text>
            <>
              {!signinClicked && (
                <>
                  <InputGroup my="14px" position="relative">
                    <InputLeftElement py="27px" ms={1}>
                      <img src={Name} alt="" width={21.64} height={24} />
                    </InputLeftElement>
                    <Input
                      focusBorderColor="#D3D5DA"
                      onFocus={() => setFNameFocused(true)}
                      onBlur={() =>
                        setFNameFocused(userInput.firstName ? true : false)
                      }
                        onChange={(e) => {
                          /* if (e.target.value === "") {
                            error.firstName = "First Name is required.";
                          } else {
                            error.firstName = "";
                          } */ setUserInput({
                            ...userInput,
                            firstName: e.target.value,
                          })
                        }
                      }
                      value={userInput?.firstName}
                      backgroundColor="white"
                      pt="33px"
                      pb="13px"
                      height="55px"
                      fontWeight={400}
                      fontSize="16px"
                      borderRadius="16px"
                      fontFamily="Poppins"
                      border={0}
                      placeholder=" "
                      type="text"
                    />
                    <FormLabel
                      position="absolute"
                      fontFamily="Poppins"
                      top={fNameFocused ? "1px" : 3}
                      color="#9EA3AE"
                      fontWeight={400}
                      fontSize={fNameFocused ? "12px" : "16px"}
                      left={10}
                      zIndex={2}
                      cursor="text"
                      onClick={() => setFNameFocused(true)}
                    >
                      First Name
                    </FormLabel>
                  </InputGroup>
                  <Text
                    color="red"
                    fontSize="14px"
                    fontWeight={400}
                    ms={3}
                    fontFamily="Poppins"
                  >
                    {error?.firstName}
                  </Text>
                </>
              )}
              {!signinClicked && (
                <>
                <InputGroup my="14px" position="relative">
                  <InputLeftElement py="27px" ms={1}>
                      <img src={Name} alt="" width={21.64} height={24} />
                  </InputLeftElement>
                  <Input
                    focusBorderColor="#D3D5DA"
                    onFocus={() => setLNameFocused(true)}
                    onBlur={() =>
                      setLNameFocused(userInput.lastName ? true : false)
                    }
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        lastName: e.target.value,
                      })
                    }
                    value={userInput?.lastName}
                    backgroundColor="white"
                    pt="33px"
                    pb="13px"
                    height="55px"
                    fontWeight={400}
                    fontSize="16px"
                    borderRadius="16px"
                    fontFamily="Poppins"
                    border={0}
                    placeholder=" "
                    type="text"
                  />
                  <FormLabel
                    position="absolute"
                    fontFamily="Poppins"
                    top={lNameFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={lNameFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    cursor="text"
                    onClick={() => setLNameFocused(true)}
                  >
                    Last Name
                  </FormLabel>
                </InputGroup>
                <Text
                    color="red"
                    fontSize="14px"
                    fontWeight={400}
                    ms={3}
                    fontFamily="Poppins"
                  >
                    {error?.lastName}
                  </Text>
                </>
                
              )}
              <InputGroup my="14px" position="relative">
                <InputLeftElement py="27px" ms={1}>
                  <img src={Mail} alt="" />
                </InputLeftElement>
                <Input
                  focusBorderColor="#D3D5DA"
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(userInput.email ? true : false)}
                  onChange={(e) =>
                    setUserInput({ ...userInput, email: e.target.value })
                  }
                  value={userInput?.email}
                  backgroundColor="white"
                  pt="33px"
                  pb="13px"
                  height="55px"
                  fontWeight={400}
                  fontSize="16px"
                  borderRadius="16px"
                  fontFamily="Poppins"
                  border={0}
                  placeholder=" "
                  type="text"
                />
                <FormLabel
                  position="absolute"
                  fontFamily="Poppins"
                  top={emailFocused ? "1px" : 3}
                  color="#9EA3AE"
                  fontWeight={400}
                  fontSize={emailFocused ? "12px" : "16px"}
                  left={10}
                  zIndex={2}
                  cursor="text"
                  onClick={() => setEmailFocused(true)}
                >
                  <FormattedMessage id="emailLabel" />
                </FormLabel>
              </InputGroup>
              <Text
                color="red"
                fontSize="14px"
                fontWeight={400}
                ms={3}
                fontFamily="Poppins"
              >
                {error?.email}
              </Text>
              <InputGroup my="14px" position="relative">
                <InputLeftElement pointerEvents="none" py="27px" ms={1}>
                  <img src={Lock} alt="" />
                </InputLeftElement>
                <Input
                  fontWeight={400}
                  fontSize="16px"
                  height="55px"
                  pt="33px"
                  pb="13px"
                  fontFamily="Poppins"
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={() =>
                    setPasswordFocused(
                      show || userInput.password ? true : false,
                    )
                  }
                  onChange={(e) =>
                    setUserInput({
                      ...userInput,
                      password: e.target.value.trim(),
                    })
                  }
                  value={userInput?.password}
                  backgroundColor="white"
                  focusBorderColor="#D3D5DA"
                  borderRadius="16px"
                  border={0}
                  placeholder=" "
                  type={show ? "text" : "password"}
                />
                <InputRightElement
                  me={1}
                  py="27px"
                  onClick={() => setShow(!show)}
                  cursor="pointer"
                  children={<img src={show ? Eye : Eyeshow} alt="" />}
                />
                <FormLabel
                  position="absolute"
                  top={passwordFocused ? "1px" : 3}
                  color="#9EA3AE"
                  fontWeight={400}
                  fontSize={passwordFocused ? "12px" : "16px"}
                  left={10}
                  zIndex={2}
                  fontFamily="Poppins"
                  cursor="text"
                  onClick={() => setPasswordFocused(true)}
                >
                  <FormattedMessage id="passwordLabel" />
                </FormLabel>
              </InputGroup>
              <Text
                color="red"
                fontSize="14px"
                fontFamily="Poppins"
                fontWeight={400}
                ms={3}
              >
                {error.password}
              </Text>
              {signinClicked && (
                <Box
                  display="flex"
                  justifyContent={signinClicked ? "start" : "center"}
                  my={6}
                >
                  <Checkbox
                    height="24px"
                    width="24px"
                    mx={3}
                    className="form_checkBox"
                    backgroundColor="white"
                    borderRadius="8px"
                    border="1.5px solid #D3D5DA"
                    boxShadow={0}
                    type="checkbox"
                    isChecked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    lineHeight="24px"
                    fontWeight={400}
                    cursor="pointer"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    <FormattedMessage
                      id={signinClicked ? "remindeme" : "acceptT_C"}
                    />
                  </Text>
                  <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    ms={1}
                    lineHeight="24px"
                    fontWeight={400}
                    color="#0A64BC"
                    cursor="pointer"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    {!signinClicked && <FormattedMessage id="privacyPolicy" />}
                  </Text>
                </Box>
              )}
              <Button
                textAlign="center"
                py="28px"
                width="100%"
                fontFamily="Poppins"
                color="white"
                borderRadius="8px"
                backgroundColor="#0A64BC"
                boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                my={"14px"}
                cursor="pointer"
                _hover={{ backgroundColor: "#0A64BC" }}
                onClick={() =>{
                  const actualErrors = handleValidation()
                  if(!actualErrors){
                    signinClicked ? handleUserSignin() : setOpenTCModal(true)
                  }else{
                    console.log("hay error")
                    console.log(error)
                  }
                }
                }
              >
                <Text
                  fontWeight={600}
                  fontSize="16px"
                  fontFamily="Poppins"
                  lineHeight="24px"
                >
                  {showLoader ? (
                    <Spinner />
                  ) : (
                    <FormattedMessage
                      id={
                        signinClicked
                          ? "signinWithEmailBtn"
                          : "signupWithEmailBtn"
                      }
                    />
                  )}
                </Text>
              </Button>
            </>

            {signinClicked && (
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#0A64BC"
                cursor="pointer"
                mt={6}
                onClick={() => setShowForgotPwd(true)}
              >
                Forgot your password?
              </Text>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  ): <></>;
};

export default SigninSignupWithCredentialsModal;
