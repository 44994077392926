import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  useBreakpoint,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface Props {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  modalContentCustomProps?: ModalContentProps;
  headerElement?: any;
  color?: string;
  idContent?:string
}
export function CommonModal({
  isOpen,
  onClose,
  children,
  modalContentCustomProps,
  headerElement,
  color = "",
  idContent,
}: PropsWithChildren<Props>) {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints === "base" || breakpoints === "sm";
  return (
    <Modal
      id={idContent}
      blockScrollOnMount={true}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={true}
      closeOnEsc={true}
      size={{ base: "95%", md: "100%", lg: "600px" }}
      motionPreset={isMobile ? "slideInBottom" : "scale"}
      
    >
      <ModalOverlay sx={{ background: "rgba(4, 40, 75, 0.8)" }} />
      <Box id={idContent}  w={{ base: "100%", md: "100%", lg: "100%" }}>
      <ModalContent
        w={{ base: "95%", md: "100%", lg: "600px" }}
        bg={color || "#F9FaFB"}
        borderRadius="20px"
        py={{ base: "20px", md: 0 }}
        {...modalContentCustomProps}
      >
        <ModalHeader
          display="flex"
          alignItems="start"
          justifyContent={"flex-end"}
          w="full"
          zIndex={200}
        >
          {headerElement}
          <Box
            display={{ base: "none", md: "flex" }}
            bg="rgba(226, 232, 240, 1)"
            width="29.15px"
            height="29.15px"
            borderRadius="5px"
            gap="10px"
            p="8px"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <svg
              style={{ cursor: "pointer" }}
              width="13"
              height="13"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.42461 8.475L2.52461 13.375C2.34128 13.5583 2.10794 13.65 1.82461 13.65C1.54128 13.65 1.30794 13.5583 1.12461 13.375C0.941276 13.1917 0.849609 12.9583 0.849609 12.675C0.849609 12.3917 0.941276 12.1583 1.12461 11.975L6.02461 7.075L1.12461 2.175C0.941276 1.99167 0.849609 1.75833 0.849609 1.475C0.849609 1.19167 0.941276 0.958333 1.12461 0.775C1.30794 0.591666 1.54128 0.5 1.82461 0.5C2.10794 0.5 2.34128 0.591666 2.52461 0.775L7.42461 5.675L12.3246 0.775C12.5079 0.591666 12.7413 0.5 13.0246 0.5C13.3079 0.5 13.5413 0.591666 13.7246 0.775C13.9079 0.958333 13.9996 1.19167 13.9996 1.475C13.9996 1.75833 13.9079 1.99167 13.7246 2.175L8.82461 7.075L13.7246 11.975C13.9079 12.1583 13.9996 12.3917 13.9996 12.675C13.9996 12.9583 13.9079 13.1917 13.7246 13.375C13.5413 13.5583 13.3079 13.65 13.0246 13.65C12.7413 13.65 12.5079 13.5583 12.3246 13.375L7.42461 8.475Z"
                fill="#667084"
              />
            </svg>
          </Box>
        </ModalHeader>
        <ModalBody
          mt={-10}
          px={{ base: "10px", md: "20px" }}
          pb={{ base: 0, md: "20px" }}
          fontFamily="Poppins"
          fontSize="sm"
          lineHeight="24px"
        >
          {children}
        </ModalBody>
      </ModalContent>
      </Box>
    </Modal>
  );
}
