import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IStock } from "../../../types/Stock";
import NewMonkScore from "../NewMonkScore";
import StockImage from "./NewStockImage";

import styles from "./styles.module.css";

interface Props {
  stock: Pick<IStock, "ticker" | "companyName" | "monkScore" | "image">;
  editMode: boolean;
  onEdit?: (
    stock: Pick<IStock, "ticker" | "companyName" | "monkScore" | "image">,
  ) => void;
  width?: string;
}

export const NewStockWithEditable = ({
  stock,
  onEdit,
  width,
  editMode,
}: Props) => {
  const navigate = useNavigate();
  const { companyName, ticker, monkScore, image } = stock;

  return (
    <Box
      height="100%"
      width={width}
      display="flex"
      flexDirection="column"
      px={"15px"}
      py={"15px"}
      rowGap={"20px"}
      alignItems="flex-start"
      justifyContent="space-between"
      backgroundColor="#F9FAFB"
      borderRadius="12px"
      cursor={!editMode ? "pointer" : ""}
      fontFamily="Rubik"
      className={styles.card}
      onClick={() => {
        if (!editMode) {
          window.scrollTo({
            behavior: "smooth",
            top: 0,
          });
          setTimeout(() => {
            navigate(`/stocks/${ticker?.split(":")[0]}/${ticker?.split(":")[1]}`)
          },300)
        } 
      }}
    >
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        h="50px"
        gap="10px"
        mb="20px"
      >
        <StockImage fullTicker={ticker} name={companyName} image={image} />
        {editMode && (
          <Box
            backgroundColor={"red.400"}
            height="21px"
            width="21px"
            minW={"21px"}
            minH="21px"
            borderRadius="50%"
            textAlign={"center"}
            onClick={(e) => {
              e.preventDefault();
              onEdit && onEdit(stock);
            }}
          >
            <Text
              color="white"
              fontSize="26px"
              fontWeight={500}
              lineHeight="20px"
            >
              -
            </Text>
          </Box>
        )}
      </Flex>

      <Flex
        w={"100%"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        columnGap={"15px"}
      >
        <Box ml={"10px"}>
          <Text
            fontSize="13.5px"
            fontWeight={500}
            lineHeight="20.25px"
            noOfLines={1}
            fontFamily="Poppins"
          >
            {companyName}
          </Text>
          <Text
            fontSize="10.5px"
            fontWeight={400}
            lineHeight="15.75px"
            color="#9EA3AE"
            fontFamily="Poppins"
          >
            {ticker?.split(".")[0]}
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          pb={"8px"}
        >
          <NewMonkScore monkScore={Math.round(monkScore)} />
        </Box>
      </Flex>
    </Box>
  );
};
