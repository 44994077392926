const MODAL_TYPES_ENUM = {
  locale: "locale",
  user: "user",
  iframe: "iframe",
  resetPassword: "resetPassword",
  pricing: "pricing",
  changePassword: "changePassword",
  afterUser: "afterUser",

};

export { MODAL_TYPES_ENUM };
