import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type AvatarCommonBoxProps = {
    onPress: () => void;
    disable?: boolean;
}
export function AvatarCommonBox(props:PropsWithChildren<AvatarCommonBoxProps>) {
    return (
        <Box
          borderRadius="50%"
          backgroundColor={props.disable ? "#E9EAEB" : "blue.100"}
          p="15px"
          color="#fff"
          mb="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w="70px"
          h="70px"
          onClick={props.onPress}
        >
          {props.children}
        </Box>
    )
}