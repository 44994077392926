import { useCallback } from "react";
import {
  CustomNegativeChartData,
  CustomNegativeChartDataProps,
} from "./CustomNegativeChart/negativeChartTypes";
import { SUPPORTED_QUARTERS } from "./CustomNegativeChart/negativeChartUtils";

export function useCustomChart() {
  const validateData = (
    initialData: CustomNegativeChartDataProps[],
  ): CustomNegativeChartDataProps[] => {
    
    const results: CustomNegativeChartDataProps[] = [];
    const reversed = [...initialData?.reverse()];
    console.log(reversed);
    reversed.forEach((item) => {
      const isDuplicated = reversed.filter(
        (i) => i.date === item.date && i.period === item.period,
      );
      const inResults = results.filter(
        (i) => i.date === item.date && i.period === item.period,
      );
      if (isDuplicated.length > 1 && inResults.length === 0) {
        results.push(isDuplicated[0]);
      } else if (isDuplicated.length === 1 && inResults.length === 0) {
        results.push(item);
      }
    });
    return [...results];
  };

  const parserData = (
    initialData: CustomNegativeChartDataProps[],
  ): CustomNegativeChartData => {
    const parsed: CustomNegativeChartData = {
      years: [],
    };

    initialData.forEach((item: any) => {
      if (!parsed.years.some((i) => i.year === item.date)) {
        parsed.years.push({
          year: item.date,
          quarters: initialData
            .filter((i) => i.date === item.date)
            .map((i) => {
              return { quarter: i.period, value: i.percentile };
            }),
        });
      }
    });

    return parsed;
  };

  const fillData = (initialData: CustomNegativeChartData) => {
    initialData.years.forEach((item) => {
      if (item.quarters.length < 4) {
        SUPPORTED_QUARTERS.forEach((quarter) => {
          if (!item.quarters.some((i) => i.quarter === quarter)) {
            item.quarters.push({
              quarter: quarter,
              value: 0,
            });
          }
        });
      }
    });

    return initialData;
  };

  const sortData = (initialData: CustomNegativeChartData) => {
    initialData.years.forEach((item) => {
      item.quarters.sort((a, b) => {
        return (
          Number.parseInt(a.quarter.slice(1)) -
          Number.parseInt(b.quarter.slice(1))
        );
      });
    });

    initialData.years.sort((a, b) => {
      return Number.parseInt(a.year) - Number.parseInt(b.year);
    });

    return initialData;
  };

  const getData = useCallback((initialData: CustomNegativeChartDataProps[]) => {
    const validated = validateData(initialData);
    const parsed = parserData(validated);
    const filled = fillData(parsed);
    const sorted = sortData(filled);
    return sorted;
  },[])

  return { getData };
}
