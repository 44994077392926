import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentApi = createApi({
  reducerPath: "paymentData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/payments`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const replacedToken = token.replace(/['"]+/g, "").trim();
        headers.set("authorization", replacedToken);
        headers.set("token", replacedToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchPaymentHistoryData: builder.query({
      query: () => {
        const user = JSON.parse(
          localStorage.getItem("user-subscription") || "",
        );
        return `/history?${encodeURIComponent(user.user.email)}`;
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updatePaymentMethod: builder.mutation({
      query: (paymentMethodId) => ({
        url: "/update-subscription-payment-method",
        method: "POST",
        body: { paymentMethodId },
      }),
    }),
  }),
});

export const {
  useFetchPaymentHistoryDataQuery,
  useUpdatePaymentMethodMutation,
} = paymentApi;
