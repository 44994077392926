import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { chartApi } from "../services/chartApi";
import { paymentApi } from "../services/paymentApi";
import { screenerApi } from "../services/screenerApi";
import { stockApi } from "../services/stockApi";
import { userApi } from "../services/userApi";
import { watchlistPortfolioApi } from "../services/watchlistPortfolioApi";
import modalReducer from "./slices/modal";
import paymentReducer from "./slices/payment";
import screenerReducer from "./slices/screener";
import stockReducer from "./slices/stock";
import userReducer from "./slices/user";
import watchlistReducer from "./slices/watchlist";
import modalShareReducer from "./slices/modalShare";

export const store = configureStore({
  reducer: {
    [stockApi.reducerPath]: stockApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [watchlistPortfolioApi.reducerPath]: watchlistPortfolioApi.reducer,
    [screenerApi.reducerPath]: screenerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    modal: modalReducer,
    watchlist: watchlistReducer,
    screener: screenerReducer,
    stock: stockReducer,
    user: userReducer,
    payment: paymentReducer,
    modalShare: modalShareReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(stockApi.middleware)
      .concat(chartApi.middleware)
      .concat(watchlistPortfolioApi.middleware)
      .concat(screenerApi.middleware)
      .concat(userApi.middleware)
      .concat(paymentApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
