import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { IRatioBadge, ScoreTimeline } from "../types/Chart";
import { calculateLTMChange } from "./Math";


export function FormatChartNumber({ payload }: { payload: number }) {
  const intl = useIntl();
  if (Math.abs(payload) < 10) {
    return intl.formatNumber(payload, {
      notation: "compact",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  return intl.formatNumber(payload, {
    notation: "compact",
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
  });
}

export const handleTitleSubtext = (value: number) => {
  if (value < 20) {
    return "Very Poor";
  } else if (value >= 20 && value < 40) {
    return "Poor";
  } else if (value >= 40 && value < 65) {
    return "Good";
  } else if (value >= 65 && value < 90) {
    return "Very Good";
  } else if (value >= 90) {
    return "Excellent";
  }
};

export const handleMonkScoreTitleSubtext = (value: number) => {
  if (value < 20) {
    return "very unlikely to beat market";
  } else if (value >= 20 && value < 40) {
    return "unlikely to beat market";
  } else if (value >= 40 && value < 65) {
    return "somewhat likely to beat market";
  } else if (value >= 65 && value < 90) {
    return "very likely to beat market";
  } else if (value >= 90) {
    return "extremely likely to beat market";
  }
};

export const handleValuationTitleSubtext = (value: number) => {
  if (value >= 90) {
    return "valuation is very cheap";
  } else if (value >= 65 && value < 90) {
    return "valuation is cheap";
  } else if (value >= 40 && value < 65) {
    return "valuation is reasonably priced";
  } else if (value >= 20 && value < 40) {
    return "valuation is expensive";
  } else {
    return "valuation is very expensive";
  }
};
export const handleShareholderTitleSubText = (value: number) => {
  if (value >= 90) {
    return "provides exceptional capital returns via dividends and buybacks";
  } else if (value >= 65 && value < 90) {
    return "provides strong capital returns via dividends and buybacks";
  } else if (value >= 40 && value < 65) {
    return "provides moderate capital returns via dividends and buybacks";
  } else if (value >= 20 && value < 40) {
    return "provides limited capital returns via dividends and buybacks";
  } else {
    return "provides minimal capital returns via dividends and buybacks";
  }
};

export const handleGrowthBadgeValueDifference = (
  scores: ScoreTimeline[],
  keyValue: string,
) => {
  if (scores.length === 0) {
    return 0;
  }
  let i = scores.length - 1;
  let indexOfValueChange = 0;
  const startDateCompare = dayjs(scores[i].processedAsOfDate);

  while (i > 0) {
    const monthDifference = startDateCompare.diff(
      dayjs(scores[i].processedAsOfDate),
      "month",
    );
    if (monthDifference > 11) {
      indexOfValueChange = i;
      break;
    }
    i--;
  }

  return (
    // inverter a ordem dos scores
    (scores[scores.length - 1][keyValue] - scores[indexOfValueChange][keyValue])
  );
};

export const handleValueDiferenceInPeriod = (
  ratios: IRatioBadge[],
  isPercentage: boolean = false,
  usePoints: boolean = false,
  keyObject: "value"|"percentile" = "value"
) => {
  if (ratios.length === 0) {
    return {
      formatDiference: isPercentage ? "0%" : "0bps",
      direction: "up"
    }
  }
  
  let initialIndex = ratios.length - 1;
  // is new value
  let firstValue = {
    value: 0,
    period: ""
  };
  // is old value
  let secondValue = {
    value: 0,
    period: ""
  };
  let correctFirstValueIndex = -1;

  //search first value that period dont zero
  while (initialIndex > 0) {
    if(ratios[initialIndex].value !== 0){
      firstValue = {
        value:ratios[initialIndex].value,
        period:ratios[initialIndex].period as any
      }
      correctFirstValueIndex = initialIndex
      break;
    }
    --initialIndex
  }

  console.log("correct value index",correctFirstValueIndex)
  //search last value that period match with first value
  if(correctFirstValueIndex===-1){
     return {
      formatDiference: isPercentage ? "0%" : "0bps",
      direction: "up"
    }
  }else{
      let i = correctFirstValueIndex - 1;
      console.log("else index",i)
      while (i > 0) {
        console.log(`${ratios[i].value}`,`${ratios[i].period}`,"firstValue:",firstValue)
        if(ratios[i].value!=firstValue.value && `${ratios[i].period}`.includes(`${firstValue.period}`)){
          secondValue = {
            value:ratios[i].value,
            period:ratios[i].period as any
          }
          break;
        }
        --i
      }
      console.log("second value after while",secondValue)
  }
  console.log("old value",secondValue.value,"new value",firstValue.value,isPercentage)
  return {
     formatDiference: calculateLTMChange(secondValue[keyObject],firstValue[keyObject],isPercentage,usePoints),
     direction: firstValue.value > secondValue.value ? "up" : "down"
  }
}

export const displayNA = (value: any) => {
  console.log("In display na", value)
  if (Number.isNaN(Number(value)) || Number(value) === 0 || Number(value) == 0) {
    return 'n/a'
  }

  return `${Math.round(value)}`
}


