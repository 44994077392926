import { Button, Text } from "@chakra-ui/react";
import { NewShareIcon } from "../../icons/ShareIcon";

function MonkStreetButton({
  lightMonkButton,
  onClick,
  isSafari,
}: {
  lightMonkButton: boolean;
  onClick?: () => void;
  isSafari?: boolean;
}) {
  return (
    <Button
      minWidth={"80px"}
      maxWidth={"80px"}
      h={"30px"}
      p={"10px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      fontSize={"10px"}
      fontWeight={700}
      backgroundColor={lightMonkButton ? "#FFF" : "#085096"}
      color={lightMonkButton ? "#085096" : "#FFF"}
      colorScheme={lightMonkButton ? "gray" : "blue"}
      onClick={onClick}
      sx={{ minW: { base: '40px', md:"80px" }, maxW: { base: '40px', md:"80px" } }}
    >
        <>
          <NewShareIcon />
          <Text sx={{ display: { base: 'none', md:"flex" } }} ml="5px">SHARE</Text>
        </>
    </Button>
  );
}

export default MonkStreetButton;
