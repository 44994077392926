import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  actualRatios,
  actualRecentCompanyRatios,
  actualStock,
} from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { ICompanyRatios } from "../../../types/Chart";
import { FormatChartNumber, handleValueDiferenceInPeriod } from "../../../utils/formatChartNumber";
import { formatMetricValueWithoutSign, roundNumber } from "../../../utils/Math";
import { ratioDictSign } from "../../../utils/ratios";
import GrowthBadge from "../../common/badges/GrowthBadge";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import TooltipModal from "../tooltipModal/tooltipModal";

const ratioDict = {
  ROIC: "returnOnInvestedCapital",
  ROE: "returnOnEquity",
  ROA: "returnOnAssets",
  "GP/Assets": "grossProfitToAssets",
  "Gross Margin": "grossMargin",
  "Operating Margin": "operatingMargin",
  "New Margin": "netMargin",
  "FCF Margin": "fcfMargin",
  ROCE: "returnOnCapitalEmployed",
  "Net Profit Margin": "netProfitMargin",
};

interface ProfitabilityCardProps {
  activeChart: "ROIC" | "ROE" | "ROA" | "GP/Assets" | "Gross Margin";
}

const getDataByYear = (
  data: ICompanyRatios[] | undefined,
  year: number,
  key:
    | "returnOnEquity"
    | "returnOnInvestedCapital"
    | "grossProfitToAssets"
    | "returnOnAssets",
) => {
  if (!data || data.length < year * 4) return 0;
  return data
    .slice(4 * year, 4 + 4 * year)
    .reduce((prev, curr) => prev + (curr[key] || 0), 0);
};

const getBarsData = (
  data: ICompanyRatios[] | undefined,
  key:
    | "returnOnEquity"
    | "returnOnInvestedCapital"
    | "grossProfitToAssets"
    | "returnOnAssets",
) => {
  if (!data) return [0, 0, 0];
  return [
    ((getDataByYear(data, 0, key) - getDataByYear(data, 1, key)) * 100) /
      Math.abs(getDataByYear(data, 1, key)),
    ((getDataByYear(data, 1, key) - getDataByYear(data, 2, key)) * 100) /
      Math.abs(getDataByYear(data, 2, key)),
    ((getDataByYear(data, 2, key) - getDataByYear(data, 3, key)) * 100) /
      Math.abs(getDataByYear(data, 3, key)),
  ];
};

const ProfitabilityCard = ({ activeChart }: ProfitabilityCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const companyRatios = useSelector(actualRecentCompanyRatios);

  const mostRecentRatio = useSelector(actualRatios);

  const stock = useSelector(actualStock);

  const getChartDetails = useCallback(() => {
    if (!companyRatios || companyRatios.length === 0)
      return {
        chartNumber: 0,
        barsData: [0, 0, 0],
        oneYearMetric: 0,
        chartData: [],
      };

    const chartNumber = FormatChartNumber({
      payload:
        companyRatios[0][ratioDict[activeChart]] !== undefined
          ? companyRatios[0][ratioDict[activeChart]] * 100
          : 0,
    });

    const barsData = getBarsData(
      companyRatios,
      ratioDict[activeChart as string],
    );
    const oneYearMetric = barsData[0] || 0;

    const ratiosData = mostRecentRatio?.statementsDate
      ? [mostRecentRatio, ...companyRatios]
      : companyRatios;

    const chartData = ratiosData
      ?.map((val, i) => ({
        time: i,
        period: val.period,
        value: (val[ratioDict[activeChart]] || 0) * 100,
        yearQ: `${val.calendarYear}`,
        date: new Date(i === 0 ? val.ratiosUpdateDate : val.statementsDate)
          ?.toLocaleDateString()
          ?.replaceAll("/", "-")
          ?.split("-")
          .reverse()
          .join("-"),
      }))
      .reverse();
    
    const oneYearMetricObject = handleValueDiferenceInPeriod(chartData,sign==="%",true)
    return {
      chartNumber: chartNumber,
      barsData,
      oneYearMetric: oneYearMetricObject.formatDiference,
      chartData,
      direction: oneYearMetricObject.direction
    };
  }, [activeChart, companyRatios, mostRecentRatio]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const sign = ratioDictSign[activeChart] ?? "%";

  return (
    <Box bgColor="#FFF" w="100%" h="100%" id="ProfitabilityCardRatios">
      <Box
        position="relative"
        mt={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
          ml="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {`${formatMetricValueWithoutSign(Number.parseFloat(getChartDetails().chartNumber.toString()),activeChart)}`}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              {sign}
            </Text>
          </Text>
        </Flex>
        <Box mb="25px" ml="10px">
        <GrowthBadge
            vector={getChartDetails().direction ?? "up"}
            meta={`${
              getChartDetails().oneYearMetric
            } last 1 year`}
          />
        </Box>

        <Box>
          <CandlestickChart
            data={getChartDetails().chartData}
            activeChart={activeChart}
            sign={sign}
          />
        </Box>

        <CompanyMetaRow
          compData={stock}
          lastUpdate={lastUpdate}
          idElementForScreenshot={`renderChart`}
          styledBox
          isChart
        />
      </Box>
      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={activeChart}
      />
    </Box>
  );
};

export default ProfitabilityCard;
