import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { closeModal, selectModal } from "../../../store/slices/modal";

import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import styles from "./styles.module.css";

function IframeModal() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useDispatch();
  const modalData = useSelector(selectModal);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const onIframeLoad = () => {
    if (iframeRef.current) {
      (iframeRef.current as HTMLIFrameElement)?.focus();
    }
  };

  return (
    <Modal
      isOpen={modalData?.isOpen && modalData.type === MODAL_TYPES_ENUM.iframe}
      onClose={handleCloseModal}
      // size='full'
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={true}
      blockScrollOnMount={true}
      scrollBehavior="inside"
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#F9FaFB"
        borderRadius="20px"
        py={{ base: "20px", md: 0 }}
        maxW="95vw"
        
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent={modalData?.name ? "space-between" : "flex-end"}
          w="full"
        >
          {modalData?.name && modalData?.name}

          <Button size={"sm"} px="6px" onClick={handleCloseModal}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </ModalHeader>
        <ModalBody
          px={{ base: "10px", md: "20px", lg: "40px", xl: "40px"  }}
          pb={{ md: "20px", lg: "40px", xl: "40px" }}
          fontFamily="Poppins"
          fontSize="sm"
          lineHeight="24px"
          overflow={"hidden"}
          height={'100%'}
          
        >
          <iframe
            src={modalData?.link}
            width="100%"
            height="800"
            className={styles.frame}
            frameBorder="0"
            ref={iframeRef}
            onLoad={onIframeLoad}
          ></iframe>
          {/* <Flex justifyContent={"flex-end"} mt="20px">
            <Button colorScheme="blue" onClick={handleCloseModal}>
              Close
            </Button>
          </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default IframeModal;
