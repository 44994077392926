import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { formatMetricValue, roundNumber } from "../../../../utils/Math";
import { useCustomChart } from "../useCustomChart";
import "./customNegativeChart.css";
import {
  CustomNegativeChartData,
  CustomNegativeChartProps,
} from "./negativeChartTypes";
import {
  dataInRange,
  defaultLegendValues,
  getMinMaxValueInData,
  legendPercentages,
  secondLegendPercenteges,
  secondReverseLegendPercenteges,
  threeLegendPercentages,
  threeReverseLegendPercentages,
} from "./negativeChartUtils";

const CustomNegativeChart = ({
  initialData,
  initialLegendValues = [],
  reverse = false,
  activeChart,
  multiplier=1,
  sign="%"
}: CustomNegativeChartProps) => {
  const {getData} = useCustomChart();
  const [data, setData] = useState<CustomNegativeChartData>(getData(initialData));
  const [legendValues, setLegendValues] = useState<(string | number)[]>(
    reverse === true
      ? initialLegendValues.length > 0
        ? initialLegendValues
        : [...defaultLegendValues(initialData)]
      : initialLegendValues.length > 0
        ? initialLegendValues
        : [...defaultLegendValues(initialData)],
  );

  //const [reverseLegendValues, setReverseLegendValues] = useState<(string | number)[]>((reverse === true) ? initialLegendValues.length > 0 ? [...initialLegendValues.reverse()] : defaultLegendValues(initialData) : initialLegendValues.length > 0 ? [...initialLegendValues.reverse()] : [...defaultLegendValues(initialData).reverse()])

  const lastBar = (
    yearIndex: number,
    barIndex: number,
    value: number,
  ): string => {
    if (
      data &&
      yearIndex === data.years.length - 1 &&
      barIndex === data.years[yearIndex].quarters.length - 1 &&
      value > 0
    ) {
      return reverse ? "last-bar-positive" : "last-bar-positive";
    }
    if (
      data &&
      yearIndex === data.years.length - 1 &&
      barIndex === data.years[yearIndex].quarters.length - 1 &&
      value < 0
    ) {
      return reverse ? "last-bar-negative" : "last-bar-positive";
    }
    return "";
  };

  const minMaxLegends = useCallback(() => {
    const { min, max } = getMinMaxValueInData(initialData as any);
    let legend = [1, 0, -1];
    if (min >= 0 && max <= 1) {
      legend = [0, 1];
    } else if (min >= -1 && max <= 0) {
      legend = [-1, 0];
    }
    return legend;
  }, [initialData]);

  const minMaxPercentages = useCallback(() => {
    const { min, max } = getMinMaxValueInData(initialData as any);
    let legend = [50, 75, 100];
    if (min >= 0 && max <= 1) {
      legend = [0, 100];
    } else if (min >= -1 && max <= 0) {
      legend = [0, 100];
    }
    return legend;
  }, [initialData]);

  const convertLegendToExponencial = useCallback((legendValue: number) => {
    if (legendValue === 0) return 0;
    if (
      legendValue.toString().length > 3 &&
      legendValue.toString().length < 5
    ) {
      return Number.parseFloat(legendValue.toFixed(3));
    }
    return Number.parseFloat(legendValue.toFixed(3)).toExponential();
  }, []);

  const calcPercentage = useCallback(
    (tope: number): number => {
      //let legend = (reverse === true) ? [...reverseLegendValues] : [...legendValues]
      let legend = [...legendValues];
      // console.log("legend", legend);

      if (legend.length === 0) return 0;
      const positiveTope = tope > 0 ? tope : tope * -1;
      let positiveMinValue = 0;
      if (reverse) {
        if (Number.parseFloat(legend[legend.length - 1].toString()) > 0) {
          positiveMinValue = Number.parseFloat(
            legend[legend.length - 1].toString(),
          );
        } else if (
          Number.parseFloat(legend[legend.length - 1].toString()) < 0
        ) {
          positiveMinValue =
            Number.parseFloat(legend[legend.length - 1].toString()) * -1;
        }
      } else {
        if (Number.parseFloat(legend[0].toString()) > 0) {
          positiveMinValue = Number.parseFloat(legend[0].toString());
        } else if (Number.parseFloat(legend[0].toString()) < 0) {
          positiveMinValue = Number.parseFloat(legend[0].toString()) * -1;
        }
      }
      let positiveMaxValue = 0;
      if (reverse) {
        if (Number.parseFloat(legend[0].toString()) > 0) {
          positiveMaxValue = Number.parseFloat(legend[0].toString());
        } else if (Number.parseFloat(legend[0].toString()) < 0) {
          positiveMaxValue = Number.parseFloat(legend[0].toString()) * -1;
        }
      } else {
        if (Number.parseFloat(legend[legend.length - 1].toString()) > 0) {
          positiveMaxValue = Number.parseFloat(
            legend[legend.length - 1].toString(),
          );
        } else if (
          Number.parseFloat(legend[legend.length - 1].toString()) < 0
        ) {
          positiveMaxValue =
            Number.parseFloat(legend[legend.length - 1].toString()) * -1;
        }
      }

      let totalPercentage = 0;
      if (reverse) {
        if (tope > 0) {
          totalPercentage =
            legend.filter((i) => Number.parseFloat(i.toString()) > 0).length /
            (legend.length - 1);
        }
        if (tope < 0) {
          totalPercentage =
            legend.filter((i) => Number.parseFloat(i.toString()) < 0).length /
            (legend.length - 1);
        }
      } else {
        if (tope > 0) {
          totalPercentage =
            legend.filter((i) => Number.parseFloat(i.toString()) > 0).length /
            (legend.length - 1);
        }
        if (tope < 0) {
          totalPercentage =
            legend.filter((i) => Number.parseFloat(i.toString()) < 0).length /
            (legend.length - 1);
        }
      }

      if (!reverse) {
        if (tope > 0) {
          if (positiveMaxValue === 0)
            return Number.parseFloat(
              (
                Number.parseFloat(positiveTope.toFixed(2)) *
                totalPercentage *
                100
              ).toFixed(1),
            );
          return Number.parseFloat(
            (
              Number.parseFloat((positiveTope / positiveMaxValue).toFixed(2)) *
              totalPercentage *
              100
            ).toFixed(1),
          );
        }
        if (tope < 0) {
          if (positiveMinValue === 0)
            return Number.parseFloat(
              (
                Number.parseFloat(positiveTope.toFixed(2)) *
                totalPercentage *
                100
              ).toFixed(1),
            );
          return Number.parseFloat(
            (
              Number.parseFloat((positiveTope / positiveMinValue).toFixed(2)) *
              totalPercentage *
              100
            ).toFixed(1),
          );
        }
      } else {
        if (tope > 0) {
          if (positiveMinValue === 0)
            return Number.parseFloat(
              (
                Number.parseFloat(positiveTope.toFixed(2)) *
                totalPercentage *
                100
              ).toFixed(1),
            );
          return Number.parseFloat(
            (
              Number.parseFloat((positiveTope / positiveMinValue).toFixed(2)) *
              totalPercentage *
              100
            ).toFixed(1),
          );
        }
        if (tope < 0) {
          if (positiveMaxValue === 0)
            return Number.parseFloat(
              (
                Number.parseFloat(positiveTope.toFixed(2)) *
                totalPercentage *
                100
              ).toFixed(1),
            );
          return Number.parseFloat(
            (
              Number.parseFloat((positiveTope / positiveMaxValue).toFixed(2)) *
              totalPercentage *
              100
            ).toFixed(1),
          );
        }
      }
      return 0;
    },
    [legendValues, reverse],
  );

  const styledTooltip = (value, percentage) => {
    if (value === 0) {
      return {
        display: "none",
      };
    }

    const bottomProperties = {
      transform: "rotate(-180deg)",
      translate: "-20px",
      transformOrigin: "top",
    };
    if (value > 50) {
      return {
        top: `-50%`,
      };
    } else if (value > 25 && value < 50) {
      return {
        top: -70,
      };
    } else if (value > 0.01 && value < 0.5) {
      return {
        top: "-70px",
      };
    } else if (value > 0 && value < 1) {
      return {
        top: -percentage,
      };
    } else if (value > 0) {
      return {
        top: -45,
      };
    } else if (value < -50) {
      return {
        bottom: `50%`,
        ...bottomProperties,
      };
    } else if (value < -25 && value > -50) {
      return {
        bottom: 70,
        ...bottomProperties,
      };
    } else if (value < -0.01 && value > -0.5) {
      return {
        top: "-30px",
        ...bottomProperties,
      };
    } else if (value < 0 && value > -1) {
      return {
        bottom: 20,
        ...bottomProperties,
      };
    } else if (value < 0) {
      return {
        bottom: 30,
        ...bottomProperties,
      };
    }
  };

  const styledArrow = (value) => {
    return value >= 0
      ? "tooltip-indicator-down triangle"
      : "tooltip-indicator-up triangle triangle-up";
  };

  const calculateBottom = () => {
    const legendInBottom = dataInRange(initialData)
      ? minMaxPercentages()
      : legendValues;
    const valIndex =
      legendPercentages[legendInBottom.findIndex((i) => i === 0)];
    // console.log("find index", valIndex);
    return valIndex ?? 50;
  };

  const tooltip = (year, quarter, value, percentage) => {
    return (
      <div
        className="custom-negative-chart-bar-tooltip"
        style={styledTooltip(value, percentage)}
      >
        <div className="custom-negative-chart-bar-wrapper">
          <Box className={styledArrow(value)}></Box>
          <Flex justifyContent={"center"} gap={2}>
            <Text
              className="cashflow"
              textAlign="center"
              color="#0A64BC"
              fontSize="8px"
              fontWeight={400}
              fontFamily="Poppins"
            >
              {`${year} ${quarter}: `}
            </Text>
            <Text
              className="cashflow"
              textAlign="center"
              color="#0A64BC"
              fontSize="8px"
              fontWeight={700}
              fontFamily="Poppins"
            >
              {formatMetricValue(Number.parseFloat((value*multiplier).toString()),activeChart)}
            </Text>
          </Flex>
        </div>
      </div>
    );
  };

  return (
    <div className="my-chart my-negative-chart">
      <div className="custom-chart-legend-bar custom-negative-chart-legend-bar">
        {reverse === true
          ? (dataInRange(initialData) ? minMaxLegends() : legendValues).map(
              (value, i, arr) => (
                <span
                  key={i}
                  className={`${i === 0 && "first-legend"}`}
                  style={{
                    position: "absolute",
                    bottom: `${(arr.length === 2 ? secondReverseLegendPercenteges[i] : arr.length === 3 ? threeReverseLegendPercentages[i] : legendPercentages[i]) - 10}%`,
                    right: "15%",
                  }}
                >
                  <span>
                    {roundNumber(Number.parseFloat(value.toString()))} {sign == 'days' ? 'd' : sign}
                  </span>
                </span>
              ),
            )
          : (dataInRange(initialData) ? minMaxLegends() : legendValues).map(
              (value, i, arr) => (
                <span
                  key={i}
                  className={`${i === 0 && "first-legend"}`}
                  style={{
                    position: "absolute",
                    bottom: `${(arr.length === 2 ? secondLegendPercenteges[i] : arr.length === 3 ? threeLegendPercentages[i] : legendPercentages[i]) - 10}%`,
                    right: "15%",
                  }}
                >
                  <span>
                    {roundNumber(Number.parseFloat(value.toString()))} {sign == 'days' ? 'd' : sign}
                  </span>
                </span>
              ),
            )}
      </div>
      <table className="custom-negative-chart charts-css column multiple hide-data data-center show-labels datasets-spacing-5">
        <tbody>
          {data &&
            data.years.length > 0 &&
            data.years.map(({ year, quarters }, index) => {
              return (
                <tr key={index}>
                  <th scope="row" className="year-xaxis">
                    {year}
                  </th>
                  {quarters.map(({ quarter, value }, i) => {
                    const percentage = calcPercentage(value);

                    return (
                      <td className="custom-negative-chart-bar" key={i}>
                        <div
                          className={`custom-negative-chart-bar-container ${reverse === true ? (value > 0 ? "negative-reverse" : "positive-reverse") : value > 0 ? "positive" : "negative"} ${lastBar(index, i, value)}`}
                          style={{
                            height: `${percentage}%`,
                            position: "absolute",
                            bottom: `${calculateBottom()}%`,
                          }}
                        >
                          {tooltip(year, quarter, value, percentage)}
                        </div>
                        <span className="data">
                          {roundNumber(Number.parseFloat(value.toString()))}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomNegativeChart;
