import { Box, Flex } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import GrowthCard from "../Stocks/CompanyScores/GrowthCard";
import HealthCard from "../Stocks/CompanyScores/HealthCard";
import ProfitabilityCard from "../Stocks/CompanyScores/ProfitabilityCard";
import ShareholderCard from "../Stocks/CompanyScores/ShareholderCard";
import ValuationCard from "../Stocks/CompanyScores/ValuationCard";
import { CommonModal } from "./Common";
import { HeaderItem } from "./HeaderItem";

interface Props {
  isOpen: boolean;
  activeChart: string;
  type: string;
  onOpen?: () => void;
  onClose: () => void;
}

export function ChartModal({ isOpen, onClose, activeChart, type }: Props) {
  console.log(activeChart);

  const renderChart = useMemo(() => {
    if (type === "profitability") {
      return <ProfitabilityCard activeChart={activeChart as any} />;
    }

    if (type === "health") {
      return <HealthCard activeChart={activeChart as any} />;
    }

    if (type === "valuation") {
      return <ValuationCard activeChart={activeChart as any} />;
    }

    if (type === "growth") {
      return <GrowthCard activeChart={activeChart as any} />;
    }

    if (type === "shareholder") {
      return <ShareholderCard activeChart={activeChart as any} />;
    }
  }, [activeChart, type]);

  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={() => {
        setShowMore(false);
        onClose();
      }}
      color={"#FFF"}
    >
    <Box id="renderChart">
      <HeaderItem
          activeChart={activeChart}
          showMore={showMore}
          onShowMore={(flag: boolean) => setShowMore(flag)}
        />
      <Flex flexDirection={"column"} alignItems={"center"} w="100%" mt="10px">
        {renderChart}
      </Flex>
    </Box>
      
    </CommonModal>
  );
}
