import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import InfoIcon from "../../../assets/svg/info.svg";
import "./tooltipModal.css";

const TooltipModal = ({ isOpen, setIsOpen, activeChart }) => {
  const getFormattedRatioName = () => {
    return activeChart === "P/FCF"
      ? "PFCF"
      : activeChart === "P/S"
        ? "PS"
        : activeChart === "Sloan Ratio"
          ? "sloan_ratio"
          : activeChart === "GP/Assets"
            ? "GP_Assets"
            : activeChart === "Net Debt to Equity"
              ? "NDTE"
              : activeChart === "Interest Coverage"
                ? "Interest_Coverage"
                : activeChart === "External Financing"
                  ? "Ex_financing"
                  : activeChart === "Net Income"
                    ? "Net_income"
                    : activeChart === "Revenue"
                      ? "Revenue"
                      : activeChart === "Shareholder Yield"
                        ? "shareHolder"
                        : activeChart === "Outstanding Shares"
                          ? "outstanding"
                          : activeChart === "Market cap"
                            ? "mktCap"
                            : activeChart === "Operating Income"
                              ? "operatingIncome"
                              : activeChart === "Probability of Default"
                                ? "probabilityOfDefault"
                                : activeChart === "Quick Ratio"
                                  ? "quickRatio"
                                  : activeChart === "Current Ratio"
                                    ? "currentRatio"
                                    : activeChart === "Operating Cash Flow"
                                      ? "operatingCashFlow"
                                      : activeChart === "Capital Expenditure"
                                        ? "capEx"
                                        : activeChart ===
                                            "Long Term Reinvestment Rate"
                                          ? "longTermReinvestmentRate"
                                          : activeChart ===
                                              "R&D to Enterprise Value"
                                            ? "rdToEnterpriseValue"
                                            : activeChart ===
                                                "LTM FCF Growth As Percentage Of Revenue"
                                              ? "ltmFCFGrowthAsPercentageOfRevenue"
                                              : activeChart ===
                                                  "R&D Index Score"
                                                ? "rdIndexScore"
                                                : activeChart ===
                                                    "Dividend Yield"
                                                  ? "dividendYield"
                                                  : activeChart ===
                                                      "Buyback Ratio"
                                                    ? "buybackYield"
                                                    : activeChart ===
                                                        "Debt Paydown"
                                                      ? "debtPaydownYield"
                                                      : activeChart ===
                                                          "EV/Revenue"
                                                        ? "evToRevenue"
                                                        : activeChart ===
                                                            "EV/Operating Income"
                                                          ? "evToOperatingIncome"
                                                          : activeChart ===
                                                              "EV/Net Income"
                                                            ? "evToNetIncome"
                                                            : activeChart ===
                                                                "EV/Assets"
                                                              ? "evToAssets"
                                                              : activeChart ===
                                                                  "PEG"
                                                                ? "priceToEarningsGrowth"
                                                                : activeChart ===
                                                                    "EV/Operating Income Growth"
                                                                  ? "evToOperatingIncomeGrowth"
                                                                  : activeChart;
  };

  // ratios which doesn't have any contents on notion
  const excludedCharts = ["longTermReinvestmentRate"];

  const thirdParaCharts = [
    "External Financing",
    "Sloan Ratio",
    "Shareholder Yield",
    "Quick Ratio",
    "Capital Expenditure",
    "R&D to Enterprise Value",
    "Dividend Yield",
    "Buyback Ratio",
    "Debt Paydown",
    "EV/Revenue",
    "EV/Operating Income",
    "EV/Assets",
    "PEG",
    "EV/Operating Income Growth",
  ];
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} blockScrollOnMount={true}>
        <ModalOverlay sx={{ background: "rgba(4, 40, 75, 0.8)" }} />
        <ModalContent
          mx={4}
          sx={{
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
            borderRadius: "12px",
          }}
          my={4}
        >
          <ModalHeader pb={2} display={"flex"} justifyContent={"space-between"}>
            <Flex>
              <Text
                fontSize="16px"
                fontFamily="Poppins"
                fontWeight="500"
                lineHeight="24px"
                display="flex"
                alignItems="center"
              >
                <FormattedMessage id={getFormattedRatioName()} />
              </Text>
            </Flex>
            <Box
            display={{ base: "none", md: "flex" }}
            bg="rgba(226, 232, 240, 1)"
            width="29.15px"
            height="29.15px"
            borderRadius="5px"
            gap="10px"
            p="8px"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            <svg
              style={{ cursor: "pointer" }}
              width="13"
              height="13"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.42461 8.475L2.52461 13.375C2.34128 13.5583 2.10794 13.65 1.82461 13.65C1.54128 13.65 1.30794 13.5583 1.12461 13.375C0.941276 13.1917 0.849609 12.9583 0.849609 12.675C0.849609 12.3917 0.941276 12.1583 1.12461 11.975L6.02461 7.075L1.12461 2.175C0.941276 1.99167 0.849609 1.75833 0.849609 1.475C0.849609 1.19167 0.941276 0.958333 1.12461 0.775C1.30794 0.591666 1.54128 0.5 1.82461 0.5C2.10794 0.5 2.34128 0.591666 2.52461 0.775L7.42461 5.675L12.3246 0.775C12.5079 0.591666 12.7413 0.5 13.0246 0.5C13.3079 0.5 13.5413 0.591666 13.7246 0.775C13.9079 0.958333 13.9996 1.19167 13.9996 1.475C13.9996 1.75833 13.9079 1.99167 13.7246 2.175L8.82461 7.075L13.7246 11.975C13.9079 12.1583 13.9996 12.3917 13.9996 12.675C13.9996 12.9583 13.9079 13.1917 13.7246 13.375C13.5413 13.5583 13.3079 13.65 13.0246 13.65C12.7413 13.65 12.5079 13.5583 12.3246 13.375L7.42461 8.475Z"
                fill="#667084"
              />
            </svg>
          </Box>
          </ModalHeader>
          
          <ModalBody pe={8}>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
            >
              <FormattedMessage
                id={
                  excludedCharts.includes(getFormattedRatioName())
                    ? "noId"
                    : `${getFormattedRatioName()}_para1`
                }
              />
            </Text>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
              mt={3}
            >
              <FormattedMessage
                id={
                  excludedCharts.includes(getFormattedRatioName())
                    ? "noId"
                    : `${getFormattedRatioName()}_para2`
                }
              />
            </Text>
            {thirdParaCharts.includes(activeChart) ? (
              <Text
                fontSize="14px"
                fontFamily="Poppins"
                fontWeight="400"
                lineHeight="24px"
                color="#475467"
                mt={3}
              >
                <FormattedMessage id={`${getFormattedRatioName()}_para3`} />
              </Text>
            ) : null}
          </ModalBody>

          <ModalFooter>
            
            
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TooltipModal;
