import { CredentialResponse, MomentListener, useGoogleOneTapLogin } from '@react-oauth/google';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { setActualUser } from '../../store/slices/user';
import { useToast } from '@chakra-ui/react';
import { decodeUserToken } from '../../utils/decode';
import { closeModal, openModal, selectModal } from '../../store/slices/modal';
import { MODAL_TYPES_ENUM } from '../../constants/variables';
import { LoggedUserModal as AfterLogguedUserModal } from "./../Modals/UserModal/AfterLoged";
import { AfterLoginPricingModal } from "../Modals/PricingModal/after";
import { useState } from 'react';

type Props = {}

export const OneTapGoogle = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const modalData = useSelector(selectModal);
    const handleCloseModal = () => dispatch(closeModal());
    const [openAfterLoginModal, setOpenAfterLoginModal] = useState(false);

    const handleToastError = (message?: string) => {
        toast({
            title: "One tap login information",
            description: message ?? "Google login faild. Please try in login page",
            status: "warning",
            duration: 9000,
            isClosable: true,
        });
    }

    const handleOneTapLogin = async (credentialResponse: CredentialResponse) => {
        const decoded = decodeUserToken(credentialResponse.credential as string)
        try {


            if (decoded?.email) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
                    { email: decoded?.email },
                );

                if (
                    response?.status === 200 &&
                    response?.data?.message ===
                    "You already have an existing account - GOOGLE "
                ) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
                        {
                            email: decoded?.email,
                            socialId: "GOOGLE",
                            flag: "GOOGLE",
                        },
                    );
                    if (response?.status === 200) {
                        localStorage.setItem("accessToken", response?.data?.data?.token);
                        localStorage.setItem("usr", JSON.stringify(response?.data?.data?.user));
                        dispatch(setActualUser(response?.data?.data?.user))
                        dispatch(openModal({ name: "UserAfterLogged", link: "", type: MODAL_TYPES_ENUM.afterUser }));
                    } else {
                        handleToastError()
                    }
                } else {
                    handleToastError("Account does not exist please signup.")
                }
            }
        } catch (error) {
            handleToastError()
        }
    }
    useGoogleOneTapLogin({
        cancel_on_tap_outside: false,
        /* promptMomentNotification: (listener) => {
                if(listener.isDismissedMoment()){
                    handleToastError("Google one tap canceled")
                }else if(listener.isSkippedMoment()){
                    handleToastError("Google one tap login skipped") 

                }
        },  */
        onSuccess: credentialResponse => {
            console.log(credentialResponse)
            handleOneTapLogin(credentialResponse);
        },
        onError: () => {
            handleToastError()
        },
    });
    return (
        <>
            {modalData.isOpen && <AfterLogguedUserModal
                open={
                    modalData.isOpen &&
                    modalData?.type === MODAL_TYPES_ENUM.afterUser
                }
                setOpen={handleCloseModal}
                setHandleUpgradeModal={() => {
                    handleCloseModal()
                    setOpenAfterLoginModal(true)
                }}
            />}

            <AfterLoginPricingModal open={openAfterLoginModal} handleCloseModal={() => setOpenAfterLoginModal(false)} />
        </>
    )
}

