import { createSlice } from "@reduxjs/toolkit";

type TModalAction = {
  type: string;
  payload?: any
};

type TUser = {
  user?:{
    firstName: string;
    lastName: string;
    photoURL?: string;
  }|null
};

const initialState: TUser = {
  user:undefined
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActualUser: (state, action: TModalAction) => {
      state.user = {...action.payload}
    },
   
  },
});

export const { setActualUser } = userSlice.actions;

export const selectActualUser = (state) => {
  if(state.user.user){
    return state.user.user
  }else{
      const usr = localStorage.getItem("usr")
      if(usr){
        return JSON.parse(usr)
      }

      return null;
  }
};
export const actualUser = (state) => state.user as TUser;

export default userSlice.reducer;
